import React, { useState, ChangeEvent, useEffect } from "react";
import { SignUpFormStep1Props } from "../../interface/SignUpFormStepPros";
import { updateUser } from "../../api/services/user";
import { User } from "../../types/user";
import { toast } from "react-toastify";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";
import useWeb3Actions from "../../authent/hooks/useWeb3Actions";
import { sendOtp, verifyOtp } from "../../api/services/otp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LogoOuibild from "../../assets/logo/logoFullPurple.svg";

export const SignUpFormStep2: React.FC<SignUpFormStep1Props> = ({
  handleNextStep,
  handlePrevStep,
}) => {
  const [userData, setUserData] = useState<Partial<User>>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false); // Track if OTP has been sent
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState<string | null>(null);
  const [resendTimer, setResendTimer] = useState(30); // Timer for resend OTP
  const [canResend, setCanResend] = useState(false); // Can resend OTP after timer

  const { userMe } = useWeb3Auth();
  const { getAccounts } = useWeb3Actions();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setCanResend(true);
      if (timer) clearInterval(timer);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [otpSent, resendTimer]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Validate international phone numbers (E.164 format)
  const isValidPhoneNumber = (phoneNumber: string) => {
    const phoneNumberRegex = /^\+\d{1,15}$/; // E.164 format
    return phoneNumberRegex.test("+" + phoneNumber);
  };

  // Send OTP and start the resend timer
  const handleSendOtp = async () => {
    if (!userData.phoneNumber) {
      toast.error("Veuillez entrer votre numéro de téléphone.");
      return;
    }

    if (!isValidPhoneNumber(userData.phoneNumber)) {
      console.log(userData.phoneNumber);
      toast.error(
        "Le numéro de téléphone doit être au format international (par exemple, +1234567890)."
      );
      return;
    }

    try {
      setLoading(true);

      // Update user with the phone number first
      const updatedUser = await updateUser({
        phoneNumber: "+" + userData.phoneNumber,
      });

      if (updatedUser) {
        const otpResponse = await sendOtp({
          phoneNumber: "+" + userData.phoneNumber,
        });
        if (otpResponse) {
          setOtpSent(true);
          setResendTimer(30);
          setCanResend(false);
          toast.success("OTP envoyé sur votre téléphone.");
        } else {
          toast.error("Erreur lors de l'envoi de l'OTP.");
        }
      } else {
        toast.error("Numéro de téléphone déjà utilisé.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'OTP:", error);
      toast.error("Erreur de réseau. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Verify OTP and update user details after OTP verification
  const handleVerifyOtp = async () => {
    if (!otp) {
      toast.error("Veuillez entrer l'OTP.");
      return;
    }
    try {
      setLoading(true);
      console.log("+" + userData.phoneNumber, otp);
      const verifyResponse = await verifyOtp({
        phoneNumber: "+" + userData.phoneNumber,
        otp,
      });

      if (verifyResponse) {
        toast.success("OTP vérifié avec succès.");

        // Update user with name, email, and wallet address after OTP verification
        const updatedUser = await updateUser({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userMe?.cognitoUser?.email,
          walletAddress: await getAccounts(),
        });

        if (updatedUser) {
          handleNextStep(); // Proceed to next step after OTP verification and user update
        } else {
          toast.error("Échec de la mise à jour des informations utilisateur.");
        }
      } else {
        setOtpError("OTP invalide. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur de vérification de l'OTP:", error);
      toast.error("Erreur lors de la vérification de l'OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    if (canResend) {
      await handleSendOtp(); // Resend OTP after timer has expired
    }
  };

  return (
    <div
      className="container d-flex align-items-center justify-content-center min-vh-100 "
      
      data-aos="fade-up"
      data-aos-duration={700}
      data-aos-delay={300}
    >
      <div
        className="row justify-content-center w-100"
        data-aos="fade-up"
        data-aos-duration={700}
        data-aos-delay={400}
      >
        <div className="col-12 col-md-10 col-lg-6 d-flex flex-column gap-40" style={{
      transform: window.innerWidth >= 992 ? "scale(0.9)" : "scale(1)",
    }}>
          <div className="d-flex" data-aos-duration={700} data-aos-delay={300}>
            <a href="/">
              <img src={LogoOuibild} alt="logo" />
            </a>
          </div>
          <div data-aos="fade-up" data-aos-duration={700} data-aos-delay={500}>
            <h2 className="title-40 bold">
              Dites-nous en <span className="purple">un peu sur vous</span>{" "}
            </h2>
            <p className="title-20 mt-3">
              Ces informations nous aideront à mieux vous connaître et à
              personnaliser votre expérience sur Ouibild.
            </p>
          </div>
          <div
            className="d-flex flex-column gap-30 w-100"
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={600}
          >
            <div>
              <label htmlFor="lastName" className="title-14 bold mb-2 d-block">
                Votre nom
              </label>
              <input
                type="text"
                id="lastName"
                placeholder="Votre nom"
                className="form-control rounded-lg shadow-none input-transparent"
                style={{ padding: "15px 20px" }}
                value={userData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="firstName" className="title-14 bold mb-2 d-block">
                Votre prénom
              </label>
              <input
                type="text"
                id="firstName"
                placeholder="Votre prénom"
                className="form-control rounded-lg shadow-none input-transparent"
                style={{ padding: "15px 20px" }}
                value={userData.firstName}
                onChange={handleInputChange}
              />
            </div>

            <div className="title-14 bold">
              <label
                htmlFor="phoneNumber"
                className="title-14 bold mb-2 d-block"
              >
                Numéro de téléphone (format international)
              </label>
              <PhoneInput
                country="fr"
                value={userData.phoneNumber || ""}
                onChange={(phone) =>
                  setUserData((prevData) => ({
                    ...prevData,
                    phoneNumber: phone,
                  }))
                }
                disabled={otpSent}
                inputStyle={{
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "transparent",
                  height: "60px",
                  border: "1px solid rgba(0, 0, 0, 0.60)",
                }}
                buttonStyle={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              />

              {!otpSent && (
                <button
                  className="button-p-purple border-r-40 mt-3 "
                  onClick={handleSendOtp}
                  disabled={loading}
                >
                  {loading ? "Chargement..." : "Envoyer OTP"}
                </button>
              )}
            </div>

            {otpSent && (
              <div>
                <label htmlFor="otp" className="title-14 bold mb-2 d-block">
                  Saisir OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  placeholder="Entrez l'OTP"
                  className="form-control rounded-lg shadow-none input-transparent"
                  style={{ padding: "15px 20px" }}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {otpError && <p className="text-danger">{otpError}</p>}
                <div className="mt-3">
                  {canResend ? (
                    <button
                      onClick={handleResendOtp}
                      className="button-p-purple mt-3 border-r-40 "
                    >
                      Renvoyer OTP
                    </button>
                  ) : (
                    <p className="text-grey">
                      Renvoyer OTP dans <strong>{resendTimer}</strong> secondes
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          {otpSent && (
            <div>
              <button
                className="button-p-purple mt-3 border-r-40 "
                onClick={handleVerifyOtp}
                disabled={loading}
              >
                {loading ? "Chargement..." : "Prochaine étape"}
              </button>
              <div className="w-100 ">
                <div
                  className="progress"
                  style={{ height: "15px", marginTop: "30px" }}
                >
                  <div
                    className="progress-bar bg-purple"
                    role="progressbar"
                    style={{ width: `25%`, height: "15px" }}
                    aria-valuenow={20}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
