import React from "react";
import "../../styles/components/Loader.css"; // Ensure to link the CSS file

const Loader = () => {
  return (
    <div className="loader-container d-flex align-items-center justify-content-center">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
