import { useState } from "react";
import BgImg from "../assets/background/backgroundOuibild.png";
import { NavProduct } from "../components/Navbar/navProduct";

export const ClientInvite = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSubmit = async () => {
    setIsModalVisible(true);
  };
  return (
    <div>
      {isModalVisible && ( <div
        className="modal-overlay d-flex justify-content-center min-vh-100"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
      >
        <div
          className="modal-content fade-in"
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "40px 30px",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <div className="d-flex justify-content-around align-items-center flex-column gap-30">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
            >
              <path
                d="M26.5917 24.866C33.2323 19.5455 41.4909 16.6524 50 16.666C58.8542 16.666 66.9917 19.7327 73.4084 24.866L79.4625 18.8118L85.3542 24.7035L79.3 30.7577C83.7071 36.275 86.4669 42.9245 87.2617 49.941C88.0565 56.9575 86.8541 64.0558 83.7929 70.4191C80.7316 76.7824 75.9358 82.1521 69.9576 85.9101C63.9793 89.6682 57.0614 91.662 50 91.662C42.9386 91.662 36.0208 89.6682 30.0425 85.9101C24.0642 82.1521 19.2685 76.7824 16.2072 70.4191C13.1459 64.0558 11.9435 56.9575 12.7383 49.941C13.5332 42.9245 16.2929 36.275 20.7 30.7577L14.6459 24.7077L20.5375 18.816L26.5917 24.8702V24.866ZM50 83.3327C53.8302 83.3327 57.623 82.5783 61.1616 81.1125C64.7003 79.6467 67.9156 77.4983 70.624 74.79C73.3323 72.0816 75.4807 68.8663 76.9465 65.3276C78.4123 61.789 79.1667 57.9962 79.1667 54.166C79.1667 50.3358 78.4123 46.5431 76.9465 43.0044C75.4807 39.4658 73.3323 36.2504 70.624 33.5421C67.9156 30.8337 64.7003 28.6853 61.1616 27.2195C57.623 25.7538 53.8302 24.9994 50 24.9994C42.2645 24.9994 34.8459 28.0723 29.3761 33.5421C23.9063 39.0119 20.8334 46.4305 20.8334 54.166C20.8334 61.9015 23.9063 69.3202 29.3761 74.79C34.8459 80.2598 42.2645 83.3327 50 83.3327V83.3327ZM54.1667 49.9994H66.6667L45.8334 77.0827V58.3327H33.3334L54.1667 31.2285V49.9994ZM33.3334 4.16602H66.6667V12.4993H33.3334V4.16602Z"
                fill="#3500A5"
              />
            </svg>
            <h2 className="m-1 mt-4 title-35 bold purple">
              Vous pourrez bientôt inviter vos clients.
            </h2>
            <p className="title-16 bold-400 grey">
              La fonctionnalité d’invitation sera disponible très bientôt.
              Bientôt, vous pourrez offrir à vos clients un accès privilégié aux
              opportunités Ouibild. Nous vous tiendrons informé dès que c’est
              prêt.
            </p>
            <button
              className="button-p-purple w-100 title-16 p-20"
              onClick={() => setIsModalVisible(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>)}
      <NavProduct />
      <section
        className="d-flex align-items-center p-120-hero"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${BgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="mb-r-20 col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-20 mb-lg-0 mx-auto order-2 order-lg-1 text-center white"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
              >
                <path
                  d="M31.035 12.5H52.5C53.163 12.5 53.7989 12.7634 54.2678 13.2322C54.7366 13.7011 55 14.337 55 15V50C55 50.663 54.7366 51.2989 54.2678 51.7678C53.7989 52.2366 53.163 52.5 52.5 52.5H7.5C6.83696 52.5 6.20107 52.2366 5.73223 51.7678C5.26339 51.2989 5 50.663 5 50V10C5 9.33696 5.26339 8.70107 5.73223 8.23223C6.20107 7.76339 6.83696 7.5 7.5 7.5H26.035L31.035 12.5ZM10 12.5V47.5H50V17.5H28.965L23.965 12.5H10ZM20 45C20 42.3478 21.0536 39.8043 22.9289 37.9289C24.8043 36.0536 27.3478 35 30 35C32.6522 35 35.1957 36.0536 37.0711 37.9289C38.9464 39.8043 40 42.3478 40 45H20ZM30 32.5C28.3424 32.5 26.7527 31.8415 25.5806 30.6694C24.4085 29.4973 23.75 27.9076 23.75 26.25C23.75 24.5924 24.4085 23.0027 25.5806 21.8306C26.7527 20.6585 28.3424 20 30 20C31.6576 20 33.2473 20.6585 34.4194 21.8306C35.5915 23.0027 36.25 24.5924 36.25 26.25C36.25 27.9076 35.5915 29.4973 34.4194 30.6694C33.2473 31.8415 31.6576 32.5 30 32.5V32.5Z"
                  fill="white"
                />
              </svg>
              <h2 className="tile-48 bold white text-center">
                Invitez votre premier client
              </h2>

              <p className="title-20 bold-300 m-0 white-50">
                Donnez à vos clients l'accès à des opportunités d'investissement
                uniques. Il ne vous reste plus qu’un clic pour les faire entrer
                dans le monde de Ouibild.
              </p>
              <div
                className="d-flex flex-column flex-md-row align-items-md-center align-items-center"
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={400}
              >
                <button
                  onClick={handleSubmit}
                  className="button-rounded-white title-16 bold d-inline-block w-auto me-md-2 mb-2 mb-md-0"
                  style={{ width: "auto" }}
                >
                  Inviter un client
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
