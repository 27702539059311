import { FirstCard, FourCard, SecondCard, ThirdCard } from "./Card";
import graphImg from "../../assets/dashboard/bgGraphFour1.png";
import graphImg2 from "../../assets/dashboard/bgGraphFour2.png";
import graphImg3 from "../../assets/dashboard/bgGraphFour3.png";
import backgroundImage from "../../assets/dashboard/bgSecond1.png";
import backgroundImage2 from "../../assets/dashboard/bgSecond2.png";
import backgroundImage3 from "../../assets/dashboard/bgSecond3.png";
export const SecondStats: React.FC = () => {
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6 md:mx-[120px] "
      data-aos="fade-right"
      data-aos-delay={300}
    >
      <SecondCard
        topRightText="+0% ce mois ci"
        number="0€"
        title="Commissions"
        description="Suivez l'évolution de vos commissions et optimisez vos performances en un coup d'œil."
        buttonText="Voir les projets"
        bgImg={backgroundImage}
      />
      <SecondCard
        topRightText="+0% ce mois ci"
        number="0€"
        title="Performance"
        description="Suivez la progression de vos performances et ajustez votre stratégie en temps réel."
        buttonText="Voir les projets"
        bgImg={backgroundImage2}
      />
      <SecondCard
        topRightText="+0% ce mois ci"
        number="0€"
        title="Répartition par secteur"
        description="Visualisez la répartition par secteur de vos investissements pour une meilleure diversification."
        buttonText="Voir les projets"
        bgImg={backgroundImage3}
      />
    </div>
  );
};

export const FirstStats: React.FC = () => {
  return (
    <div
      data-aos="fade-right"
      data-aos-delay={300}
      className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-5 lg:gap-6 md:mx-[120px] "
    >
      <FirstCard
        topRightText="+0% ce mois ci"
        number="0"
        label="Nombre de projets"
        svgIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        }
      />
      <FirstCard
        topRightText="+0% ce mois ci"
        number="0"
        label="Nombre de clients"
        svgIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        }
      />
      <FirstCard
        topRightText="+0% ce mois ci"
        number="0€"
        label="Montant total levés"
        svgIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        }
      />
      <FirstCard
        topRightText="+0% ce mois ci"
        number=""
        label="Rendement moyen"
        svgIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        }
      />
      <FirstCard
        topRightText="+0% ce mois ci"
        number="8888"
        label="Classement"
        svgIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        }
      />
    </div>
  );
};

export const ThirdStats: React.FC = () => {
  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 md:mx-[120px]"
      data-aos="fade-right"
      data-aos-delay={300}
    >
      <ThirdCard
        title="Meilleurs clients"
        description="Découvrez vos clients les plus performants et maximisez vos opportunités avec eux."
        buttonText="Voir les projets"
      />
      <ThirdCard
        title="Projets investis"
        description="Découvrez vos clients les plus performants et maximisez vos opportunités avec eux."
        buttonText="Voir les projets"
      />
    </div>
  );
};

export const FourStats: React.FC = () => {
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6 md:mx-[120px] mt-4"
      data-aos="fade-right"
      data-aos-delay={300}
    >
      <FourCard
        title="Inviter un client"
        description="Diversifiez les portefeuilles de vos clients avec des produits d'investissement innovants et variés."
        buttonText="Voir les projets"
        bgImg={graphImg}
      />
      <FourCard
        title="Apprenez Ouibild"
        description="Diversifiez les portefeuilles de vos clients avec des produits d'investissement innovants et variés."
        buttonText="Voir les projets"
        bgImg={graphImg2}
      />

      <FourCard
        title="Besoin d’assistance ?"
        description="Diversifiez les portefeuilles de vos clients avec des produits d'investissement innovants et variés."
        buttonText="Voir les projets"
        bgImg={graphImg3}
      />
    </div>
  );
};
