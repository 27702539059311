import { Amplify } from "aws-amplify";

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: "28u3qf8lmrei7a1pm875di87vf",
        userPoolId: "eu-central-1_GyErYwiR4",
        loginWith: {
          // Optional
          oauth: {
            domain: "ouibuild.auth.eu-central-1.amazoncognito.com",
            scopes: [
              "email phone openid aws.cognito.signin.user.admin profile",
            ],
            redirectSignIn: [
              "http://localhost:3000/signup",
              "http://localhost:3000",
              "https://invest.dukan-edition.pro/signup",
              "https://invest.klapee.pro",
              "https://invest.patrimoine-indivis.pro/signup",
              "https://invest.tahuri-impact.pro/signup",
              "https://ouibild.io",
              "https://ouibild.io/signup",
              "https://ouibild-dapp-w4vf.vercel.app",
              "https://ouibild-dapp-w4vf.vercel.app/signup",
              "https://ouibild-dapp-w4vf-git-dev-availabs.vercel.app",
              "https://ouibild-dapp-w4vf-git-dev-availabs.vercel.app/signup",
              "https://ouibuild.auth.eu-central-1.amazoncognito.com",
            ],
            redirectSignOut: [
              "http://localhost:3000/signup",
              "http://localhost:3000",
              "https://invest.dukan-edition.pro/signup",
              "https://invest.klapee.pro",
              "https://invest.patrimoine-indivis.pro/signup",
              "https://invest.tahuri-impact.pro/signup",
              "https://ouibild.io",
              "https://ouibild.io/signup",
              "https://ouibild-dapp-w4vf.vercel.app",
              "https://ouibild-dapp-w4vf.vercel.app",
              "https://ouibild-dapp-w4vf-git-dev-availabs.vercel.app",
              "https://ouibild-dapp-w4vf-git-dev-availabs.vercel.app/signup",
              "https://ouibuild.auth.eu-central-1.amazoncognito.com",
            ],
            responseType: "code",
          },
          email: true, // Optional
          phone: true,
        },
      },
    },
  });
};
