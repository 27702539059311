import { useEffect, useState } from "react";
import LogoPurple from "../../assets/logo/logoFullPurple.svg";
import bgImg from "../../assets/background/backgroundCard.png";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";
import useWeb3Actions from "../../authent/hooks/useWeb3Actions";
import useAbstractConnect from "../../authent/hooks/useAbstractConnect";

export const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { loggedIn, userMe } = useWeb3Auth();

  const { fullLogout } = useAbstractConnect();

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleOverlayClick = () => {
    setIsNavOpen(false);
    const navbarToggler = document.querySelector(
      ".navbar-toggler"
    ) as HTMLElement;
    if (navbarToggler) {
      navbarToggler.click(); // Simule un clic sur le bouton de la navbar
    }
  };

  // Ajoutez ou retirez la classe "no-scroll" en fonction de l'état de la navbar
  useEffect(() => {
    if (isNavOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      // Clean up pour enlever la classe si le composant est démonté
      document.body.classList.remove("no-scroll");
    };
  }, [isNavOpen]);

  return (
    <>
      {/*NAV DESKTOP*/}

      <div
        className="container-nav fixed-top d-none d-lg-block"
        style={{ top: 20 }}
      >
        <div className="navbarz " style={{ transition: "top 0.3s" }}>
          <div className="row align-items-center justify-content-around">
            <div className="col-2">
              <a href="/">
                <img src={LogoPurple} alt="Logo" />
              </a>
            </div>
            <div className="col-6 text-center">
              <a className="simple-link m-3" href="#avantages">
                Avantages
              </a>
              <a className="simple-link m-3" href="#projets">
                Projets
              </a>
              <a className="simple-link m-3" href="#how">
                Comment ça marche
              </a>
              <a className="simple-link m-3" href="/ecosysteme">
                Ecosystème
              </a>
            </div>

            {loggedIn === true && !userMe?.form ? (
              // Cas où l'utilisateur est connecté mais il n'a pas de form
              <div className="text-end col-4 d-flex justify-content-end">
                <a
                  href="/signup"
                  className="button-p-purple p-10-20"
                  style={{ marginRight: "5px" }}
                >
                  Finaliser mon inscription
                </a>
                <a href="/dashboard" className="button-p-purple p-10-20">
                  Dashboard
                </a>
              </div>
            ) : loggedIn === true && userMe?.firstName && userMe?.lastName ? (
              // Cas où l'utilisateur est connecté et a un firstName et lastName
              <div className="col-4 text-end d-flex justify-content-end">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <a href="/dashboard" className="button-p-purple p-10-20 me-2">
                    Dashboard
                  </a>
                </div>
                <button
                  className="button-p-purple p-10-20"
                  onClick={fullLogout}
                >
                  Déconnexion
                </button>
              </div>
            ) : (
              // Cas où l'utilisateur n'est pas connecté
              <div className="col-3 text-end d-flex justify-content-end">
                <a href="/signup" className="button-p-purple p-10-20">
                  Commencer maintenant
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*NAV MOBILE*/}
      <nav
        className={`navbar navbar-mobile navbar-expand-lg d-lg-none ${
          isNavOpen ? "bg-white" : ""
        }`}
        style={{
          padding: "15px",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1050,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        <img src={LogoPurple} alt="" width="85px" />
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={isNavOpen}
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
            >
              <path
                d="M0 0.203125H18V2.20312H0V0.203125ZM0 7.20312H18V9.20312H0V7.20312ZM0 14.2031H18V16.2031H0V14.2031Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div
          className="collapse navbar-collapse"
          id="navbarNavDropdown"
          style={{
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <div className="container">
            <div className="d-flex flex-column gap-10">
              <a
                href="/dashboard"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                <div className="card-nav d-flex align-items-center">
                  {" "}
                  <div className="d-flex align-items-center">
                    <p className="mb-0 ms-2 title-16 bold-600">Dashboard</p>
                  </div>
                  <img
                    src={bgImg}
                    alt="Right Image"
                    className="img-fluid border-r-right"
                    style={{
                      maxHeight: "60px",
                      width: "150px",
                    }}
                  />
                </div>
              </a>

              <a
                href="/products"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                <div className="card-nav d-flex align-items-center">
                  {" "}
                  <div className="d-flex align-items-center">
                    <p className="mb-0 ms-2 title-16 bold-600">
                      Projets disponibles
                    </p>
                  </div>
                  <img
                    src={bgImg}
                    alt="Right Image"
                    className="img-fluid border-r-right"
                    style={{
                      maxHeight: "60px",
                      width: "150px",
                    }}
                  />
                </div>
              </a>

              <a
                href="/client"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                <div className="card-nav d-flex align-items-center">
                  {" "}
                  <div className="d-flex align-items-center">
                    <p className="mb-0 ms-2 title-16 bold-600">Mes clients</p>
                  </div>
                  <img
                    src={bgImg}
                    alt="Right Image"
                    className="img-fluid border-r-right"
                    style={{
                      maxHeight: "60px",
                      width: "150px",
                    }}
                  />
                </div>
              </a>

              <a
                href="/ecosysteme"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                <div className="card-nav d-flex align-items-center">
                  {" "}
                  <div className="d-flex align-items-center">
                    <p className="mb-0 ms-2 title-16 bold-600">Ecosystème</p>
                  </div>
                  <img
                    src={bgImg}
                    alt="Right Image"
                    className="img-fluid border-r-right"
                    style={{
                      maxHeight: "60px",
                      width: "150px",
                    }}
                  />
                </div>
              </a>

              {loggedIn === true && !userMe?.form ? (
                <div>
                  <a href="/signup" className="button-p-purple">
                    Finaliser mon inscription
                  </a>
                </div>
              ) : (
                // Cas où l'utilisateur n'est pas connecté
                <a href="/signup" className="button-p-purple ">
                  Devenir Membre
                </a>
              )}
            </div>
          </div>
        </div>
      </nav>
      {isNavOpen && (
        <div
          onClick={handleOverlayClick}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.30)",
            zIndex: 1049,
          }}
        ></div>
      )}
    </>
  );
};
