import { useEffect, useState } from "react";
import LogoWhite from "../../assets/logo/logoOuibildWhite.svg";
import LogoPurple from "../../assets/logo/logoPurple.svg";
import bgNav3 from "../../assets/navbar/bgNav3.png";
import bgNav from "../../assets/navbar/bgNav.png";
import bgNav2 from "../../assets/navbar/bgNav2.png";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";
import { Profil } from "../profil/profil";

export const NavProduct = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isProfilOpen, setIsProfilOpen] = useState(false);
  const { userMe, loggedIn } = useWeb3Auth();

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
    setIsProfilOpen(false);
  };

  const handleToggleProfile = () => {
    setIsProfilOpen(!isProfilOpen);
  };

  const handleOverlayClick = () => {
    setIsNavOpen(false);
    const navbarToggler = document.querySelector(
      ".navbar-toggler"
    ) as HTMLElement;
    if (navbarToggler) {
      navbarToggler.click(); // Simule un clic sur le bouton de la navbar
    }
  };

  const handleOverlayClickProfile = () => {
    setIsProfilOpen(false);
    const navbarToggler = document.querySelector(
      ".profil-dropwdown"
    ) as HTMLElement;
    if (navbarToggler) {
      navbarToggler.click(); // Simule un clic sur le bouton de la navbar
    }
  };

  // Ajoutez ou retirez la classe "no-scroll" en fonction de l'état de la navbar
  useEffect(() => {
    if (isNavOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    if (isProfilOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      // Clean up pour enlever la classe si le composant est démonté
      document.body.classList.remove("no-scroll");
    };
  }, [isNavOpen, isProfilOpen]);

  return (
    <>
      {/*NAV DESKTOP*/}
      <div>
        <div
          className="container-nav-product fixed-top d-none d-lg-block mx-auto"
          style={{ top: 20 }}
        >
          <div className="navbarz" style={{ transition: "top 0.3s" }}>
            <div className="row align-items-center">
              <div className="col-3">
                {userMe?.form?.companyName ? (
                  <h3 className="title-20 bold-600 text-white">
                    {userMe?.form?.companyName}
                  </h3>
                ) : (
                  <a href="/">
                    <img src={LogoWhite} alt="Logo" />
                  </a>
                )}
              </div>
              <div className="col-6 text-center">
                <a
                  className="simple-link  mx-4 d-inline-flex align-items-center"
                  href="/dashboard"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className="me-1"
                  >
                    <path
                      d="M1.5 5.5C1.5 6.05228 1.94772 6.5 2.5 6.5H4.5C5.05228 6.5 5.5 6.05228 5.5 5.5V2.5C5.5 1.94772 5.05228 1.5 4.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V5.5ZM1.5 9.5C1.5 10.0523 1.94772 10.5 2.5 10.5H4.5C5.05228 10.5 5.5 10.0523 5.5 9.5V8.5C5.5 7.94772 5.05228 7.5 4.5 7.5H2.5C1.94772 7.5 1.5 7.94772 1.5 8.5V9.5ZM6.5 9.5C6.5 10.0523 6.94772 10.5 7.5 10.5H9.5C10.0523 10.5 10.5 10.0523 10.5 9.5V6.5C10.5 5.94772 10.0523 5.5 9.5 5.5H7.5C6.94772 5.5 6.5 5.94772 6.5 6.5V9.5ZM7.5 1.5C6.94772 1.5 6.5 1.94772 6.5 2.5V3.5C6.5 4.05228 6.94772 4.5 7.5 4.5H9.5C10.0523 4.5 10.5 4.05228 10.5 3.5V2.5C10.5 1.94772 10.0523 1.5 9.5 1.5H7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  Dashboard
                </a>
                <a
                  className="simple-link  mx-4 d-inline-flex align-items-center"
                  href="/products"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className="me-1"
                  >
                    <path
                      d="M10.0414 7.59991L10.6424 7.96041C10.6795 7.98261 10.7102 8.01404 10.7315 8.05163C10.7528 8.08922 10.764 8.1317 10.764 8.17491C10.764 8.21813 10.7528 8.2606 10.7315 8.29819C10.7102 8.33579 10.6795 8.36722 10.6424 8.38941L6.25743 11.0204C6.17966 11.0671 6.09065 11.0918 5.99993 11.0918C5.90921 11.0918 5.82019 11.0671 5.74243 11.0204L1.35743 8.38941C1.32035 8.36722 1.28966 8.33579 1.26835 8.29819C1.24704 8.2606 1.23584 8.21813 1.23584 8.17491C1.23584 8.1317 1.24704 8.08922 1.26835 8.05163C1.28966 8.01404 1.32035 7.98261 1.35743 7.96041L1.95843 7.59991L5.99993 10.0249L10.0414 7.59991ZM10.0414 5.24991L10.6424 5.61041C10.6795 5.63261 10.7102 5.66404 10.7315 5.70163C10.7528 5.73922 10.764 5.7817 10.764 5.82491C10.764 5.86813 10.7528 5.9106 10.7315 5.94819C10.7102 5.98579 10.6795 6.01722 10.6424 6.03941L5.99993 8.82491L1.35743 6.03941C1.32035 6.01722 1.28966 5.98579 1.26835 5.94819C1.24704 5.9106 1.23584 5.86813 1.23584 5.82491C1.23584 5.7817 1.24704 5.73922 1.26835 5.70163C1.28966 5.66404 1.32035 5.63261 1.35743 5.61041L1.95843 5.24991L5.99993 7.67491L10.0414 5.24991ZM6.25693 0.654413L10.6424 3.28541C10.6795 3.30761 10.7102 3.33904 10.7315 3.37663C10.7528 3.41422 10.764 3.4567 10.764 3.49991C10.764 3.54313 10.7528 3.5856 10.7315 3.62319C10.7102 3.66079 10.6795 3.69222 10.6424 3.71441L5.99993 6.49991L1.35743 3.71441C1.32035 3.69222 1.28966 3.66079 1.26835 3.62319C1.24704 3.5856 1.23584 3.54313 1.23584 3.49991C1.23584 3.4567 1.24704 3.41422 1.26835 3.37663C1.28966 3.33904 1.32035 3.30761 1.35743 3.28541L5.74243 0.654413C5.82019 0.607691 5.90921 0.583008 5.99993 0.583008C6.09065 0.583008 6.17966 0.607691 6.25743 0.654413H6.25693ZM5.99993 1.66591L2.94343 3.49991L5.99993 5.33391L9.05643 3.49991L5.99993 1.66591Z"
                      fill="currentColor"
                    />
                  </svg>
                  Projets disponibles
                </a>
                <a
                  className="simple-link  mx-4 d-inline-flex align-items-center"
                  href="/client"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className="me-1"
                  >
                    <path
                      d="M6.207 2.5H10.5C10.6326 2.5 10.7598 2.55268 10.8536 2.64645C10.9473 2.74021 11 2.86739 11 3V10C11 10.1326 10.9473 10.2598 10.8536 10.3536C10.7598 10.4473 10.6326 10.5 10.5 10.5H1.5C1.36739 10.5 1.24021 10.4473 1.14645 10.3536C1.05268 10.2598 1 10.1326 1 10V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H5.207L6.207 2.5ZM2 2.5V9.5H10V3.5H5.793L4.793 2.5H2ZM4 9C4 8.46957 4.21071 7.96086 4.58579 7.58579C4.96086 7.21071 5.46957 7 6 7C6.53043 7 7.03914 7.21071 7.41421 7.58579C7.78929 7.96086 8 8.46957 8 9H4ZM6 6.5C5.66848 6.5 5.35054 6.3683 5.11612 6.13388C4.8817 5.89946 4.75 5.58152 4.75 5.25C4.75 4.91848 4.8817 4.60054 5.11612 4.36612C5.35054 4.1317 5.66848 4 6 4C6.33152 4 6.64946 4.1317 6.88388 4.36612C7.1183 4.60054 7.25 4.91848 7.25 5.25C7.25 5.58152 7.1183 5.89946 6.88388 6.13388C6.64946 6.3683 6.33152 6.5 6 6.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  Mes clients
                </a>
              </div>
              <div className="col-3">
                {loggedIn === true ? (
                  <div
                    className=" cursor-pointer"
                    onClick={handleToggleProfile}
                  >
                    <div className="text-end d-flex justify-content-end">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="card-profil-product">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="currentColor"
                          >
                            <circle cx="11" cy="11" r="10.5" fill="#D9D9D9" />
                          </svg>
                          <p className="profil-name">
                            {userMe
                              ? `${userMe.firstName ?? "Lorem"} ${
                                  userMe.lastName ?? "Ipsum"
                                }`.trim()
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-end d-flex justify-content-end">
                    <a
                      href="/signup"
                      className="button-p-purple p-10-20 border-r-6"
                    >
                      Devenir Membre
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Profil show={isProfilOpen} setShow={setIsProfilOpen} />
        </div>
      </div>

      {/*NAV MOBILE*/}
      <nav
        className={`navbar navbar-expand-lg navbar-product d-lg-none ${
          isNavOpen ? "background-white" : ""
        }`}
        style={{
          padding: "15px",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1048,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a href="/">
          <img src={isNavOpen ? LogoPurple : LogoWhite} alt="Logo" width="" />
        </a>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <a
            href="/client"
            className={`btn-nav-product ${
              isNavOpen ? "bg-light-purple purple" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.5 1.3457C3.58425 1.3457 3.66714 1.36699 3.74096 1.40759L22.2034 11.5619C22.4454 11.695 22.5337 11.999 22.4006 12.241C22.3549 12.3241 22.2865 12.3925 22.2034 12.4382L3.74096 22.5925C3.499 22.7256 3.19497 22.6373 3.06189 22.3954C3.02129 22.3215 3 22.2387 3 22.1544V1.8457C3 1.56956 3.22386 1.3457 3.5 1.3457ZM5 4.38261V11H10V13H5V19.6175L18.8499 12L5 4.38261Z"
                fill="currentColor"
              />
            </svg>
          </a>

          <a
            onClick={handleToggleProfile}
            className={`btn-nav-product cursor-pointer ${
              isNavOpen ? "bg-light-purple purple" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H4ZM13 16.083V20H17.6586C16.9423 17.9735 15.1684 16.4467 13 16.083ZM11 20V16.083C8.83165 16.4467 7.05766 17.9735 6.34141 20H11ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.2104 11 16 9.21043 16 7C16 4.78957 14.2104 3 12 3C9.78957 3 8 4.78957 8 7C8 9.21043 9.78957 11 12 11Z"
                fill="currentColor"
              />
            </svg>
          </a>

          <button
            className="navbar-toggler p-0 cursor-pointer"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={isNavOpen}
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span
              className={`btn-nav-product ${
                isNavOpen ? "bg-light-purple purple" : ""
              }`}
            >
              {isNavOpen ? (
                // SVG différent lorsque isNavOpen est true
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="#3500A5"
                >
                  <path
                    d="M2 2L16 14M2 14L16 2"
                    stroke="#3500A5"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                // SVG par défaut lorsque isNavOpen est false
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                >
                  <path
                    d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </span>
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="navbarNavDropdown"
          style={{
            overflowY: "auto",
            marginTop: "20px",
          }}
        >
          <div className="container">
            <div className="d-flex flex-column gap-10">
              <div className="card-nav">
                <div className="d-flex align-items-center">
     <a href="/dashboard" className="d-inline-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M2 7.66667C2 8.21895 2.44772 8.66667 3 8.66667H6.33333C6.88562 8.66667 7.33333 8.21895 7.33333 7.66667V3C7.33333 2.44771 6.88562 2 6.33333 2H3C2.44772 2 2 2.44772 2 3V7.66667ZM2 13C2 13.5523 2.44772 14 3 14H6.33333C6.88562 14 7.33333 13.5523 7.33333 13V11C7.33333 10.4477 6.88562 10 6.33333 10H3C2.44772 10 2 10.4477 2 11V13ZM8.66667 13C8.66667 13.5523 9.11438 14 9.66667 14H13C13.5523 14 14 13.5523 14 13V8.33333C14 7.78105 13.5523 7.33333 13 7.33333H9.66667C9.11438 7.33333 8.66667 7.78105 8.66667 8.33333V13ZM9.66667 2C9.11438 2 8.66667 2.44772 8.66667 3V5C8.66667 5.55228 9.11438 6 9.66667 6H13C13.5523 6 14 5.55228 14 5V3C14 2.44772 13.5523 2 13 2H9.66667Z"
                      fill="#3500A5"
                    />
                  </svg>
                  <p className="mb-0 ms-2 title-16 bold-600">Dashboard</p>
                  </a>
                </div>
                <img
                  src={bgNav3}
                  alt="Right Image"
                  className="img-fluid"
                  style={{ height: "100%", width: "150px" }}
                />
                
              </div>

              <div className="card-nav ">
                <div className="d-flex align-items-center">
                <a href="/products" className="d-inline-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M13.3882 10.1332L14.1896 10.6139C14.239 10.6435 14.2799 10.6854 14.3083 10.7355C14.3368 10.7856 14.3517 10.8423 14.3517 10.8999C14.3517 10.9575 14.3368 11.0141 14.3083 11.0643C14.2799 11.1144 14.239 11.1563 14.1896 11.1859L8.34291 14.6939C8.23922 14.7562 8.12054 14.7891 7.99958 14.7891C7.87862 14.7891 7.75993 14.7562 7.65624 14.6939L1.80958 11.1859C1.76014 11.1563 1.71922 11.1144 1.69081 11.0643C1.6624 11.0141 1.64746 10.9575 1.64746 10.8999C1.64746 10.8423 1.6624 10.7856 1.69081 10.7355C1.71922 10.6854 1.76014 10.6435 1.80958 10.6139L2.61091 10.1332L7.99958 13.3665L13.3882 10.1332ZM13.3882 6.99988L14.1896 7.48055C14.239 7.51015 14.2799 7.55205 14.3083 7.60217C14.3368 7.6523 14.3517 7.70893 14.3517 7.76655C14.3517 7.82417 14.3368 7.8808 14.3083 7.93093C14.2799 7.98105 14.239 8.02295 14.1896 8.05255L7.99958 11.7666L1.80958 8.05255C1.76014 8.02295 1.71922 7.98105 1.69081 7.93093C1.6624 7.8808 1.64746 7.82417 1.64746 7.76655C1.64746 7.70893 1.6624 7.6523 1.69081 7.60217C1.71922 7.55205 1.76014 7.51015 1.80958 7.48055L2.61091 6.99988L7.99958 10.2332L13.3882 6.99988ZM8.34224 0.87255L14.1896 4.38055C14.239 4.41015 14.2799 4.45205 14.3083 4.50217C14.3368 4.5523 14.3517 4.60893 14.3517 4.66655C14.3517 4.72417 14.3368 4.7808 14.3083 4.83093C14.2799 4.88105 14.239 4.92295 14.1896 4.95255L7.99958 8.66655L1.80958 4.95255C1.76014 4.92295 1.71922 4.88105 1.69081 4.83093C1.6624 4.7808 1.64746 4.72417 1.64746 4.66655C1.64746 4.60893 1.6624 4.5523 1.69081 4.50217C1.71922 4.45205 1.76014 4.41015 1.80958 4.38055L7.65624 0.87255C7.75993 0.810255 7.87862 0.777344 7.99958 0.777344C8.12054 0.777344 8.23922 0.810255 8.34291 0.87255H8.34224ZM7.99958 2.22122L3.92424 4.66655L7.99958 7.11188L12.0749 4.66655L7.99958 2.22122Z"
                      fill="#3500A5"
                    />
                  </svg>
                  <p className="mb-0 ms-2 title-16 bold-600">
                    Projets disponibles
                  </p>
                  </a>
                </div>
                <img
                  src={bgNav}
                  alt="Right Image"
                  className="img-fluid"
                  style={{ height: "100%", width: "150px" }}
                />
              </div>

              <div className="card-nav ">
                <div className="d-flex align-items-center">
                <a href="/client" className="d-inline-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.27567 3.33333H13.9997C14.1765 3.33333 14.3461 3.40357 14.4711 3.5286C14.5961 3.65362 14.6663 3.82319 14.6663 4V13.3333C14.6663 13.5101 14.5961 13.6797 14.4711 13.8047C14.3461 13.9298 14.1765 14 13.9997 14H1.99967C1.82286 14 1.65329 13.9298 1.52827 13.8047C1.40325 13.6797 1.33301 13.5101 1.33301 13.3333V2.66667C1.33301 2.48986 1.40325 2.32029 1.52827 2.19526C1.65329 2.07024 1.82286 2 1.99967 2H6.94234L8.27567 3.33333ZM2.66634 3.33333V12.6667H13.333V4.66667H7.72367L6.39034 3.33333H2.66634ZM5.33301 12C5.33301 11.2928 5.61396 10.6145 6.11406 10.1144C6.61415 9.61428 7.29243 9.33333 7.99967 9.33333C8.70692 9.33333 9.3852 9.61428 9.88529 10.1144C10.3854 10.6145 10.6663 11.2928 10.6663 12H5.33301ZM7.99967 8.66667C7.55765 8.66667 7.13372 8.49107 6.82116 8.17851C6.5086 7.86595 6.33301 7.44203 6.33301 7C6.33301 6.55797 6.5086 6.13405 6.82116 5.82149C7.13372 5.50893 7.55765 5.33333 7.99967 5.33333C8.4417 5.33333 8.86563 5.50893 9.17819 5.82149C9.49075 6.13405 9.66634 6.55797 9.66634 7C9.66634 7.44203 9.49075 7.86595 9.17819 8.17851C8.86563 8.49107 8.4417 8.66667 7.99967 8.66667Z"
                      fill="#3500A5"
                    />
                  </svg>
                  <p className="mb-0 ms-2 title-16 bold-600">Mes clients</p>
                  </a>
                </div>
                <img
                  src={bgNav2}
                  alt="Right Image"
                  className="img-fluid"
                  style={{ height: "100%", width: "150px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="d-lg-none">
        <Profil show={isProfilOpen} setShow={setIsProfilOpen} />
      </div>

      {isNavOpen && (
        <div
          onClick={handleOverlayClick}
          className="d-lg-none"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.30)",
            zIndex: 1047,
          }}
        ></div>
      )}

      {isProfilOpen && (
        <div
          onClick={handleOverlayClickProfile}
          className="d-lg-none"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.30)",
            zIndex: 1049,
          }}
        ></div>
      )}
    </>
  );
};
