import bgPurple from "../../assets/background/bigBgPurple.png";
import React, { useEffect, useState } from "react";
import useAbstractConnect from "../../authent/hooks/useAbstractConnect";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";
import { getPassCard } from "../../api/services/user";
import profil from "../../assets/profil/profile-picture.png";
import { Loading } from "../Loading/Loading";
import "../../styles/components/Profil.css";
interface ProfilProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

export const Profil: React.FC<ProfilProps> = ({ show, setShow }) => {
  const [passCard, setPassCard] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { fullLogout } = useAbstractConnect();
  const { userMe } = useWeb3Auth();

  useEffect(() => {
    if (userMe && userMe.id) {
      setIsLoading(true);
      getPassCard(userMe.id).then((res) => {
        setIsLoading(false);
        if (res) {
          setPassCard(res);
        }
      });
    }
  }, [userMe]);

  if (!show) return null;
  return (
    <div className="profile-dropdown mh-full">
      {/* Ligne avec le SVG à gauche, le texte au milieu, et le SVG à droite */}
      <div className="d-flex justify-content-between align-items-center mb-3 ">
        <svg
          onClick={fullLogout}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="cursor-pointer"
        >
          <path
            d="M6.265 3.807L7.412 5.446C6.01818 6.42177 4.97176 7.8165 4.42474 9.4276C3.87772 11.0387 3.8586 12.7822 4.37015 14.4049C4.88171 16.0276 5.89729 17.445 7.26937 18.4511C8.64146 19.4572 10.2986 19.9996 12 19.9996C13.7014 19.9996 15.3585 19.4572 16.7306 18.4511C18.1027 17.445 19.1183 16.0276 19.6299 14.4049C20.1414 12.7822 20.1223 11.0387 19.5753 9.4276C19.0282 7.8165 17.9818 6.42177 16.588 5.446L17.735 3.807C19.053 4.72843 20.1289 5.95446 20.8715 7.38087C21.614 8.80728 22.0012 10.3919 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C1.99884 10.3919 2.38599 8.80728 3.12853 7.38087C3.87107 5.95446 4.94705 4.72843 6.265 3.807ZM11 12V2H13V12H11Z"
            fill="#D45050"
          />
        </svg>
        <p className="mb-0 text-center title-16 bold-400 purple">Profile</p>
        <svg
          onClick={() => setShow(false)}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="cursor-pointer"
        >
          <path
            d="M12.0002 10.5857L16.9502 5.63574L18.3642 7.04974L13.4142 11.9997L18.3642 16.9497L16.9502 18.3637L12.0002 13.4137L7.05023 18.3637L5.63623 16.9497L10.5862 11.9997L5.63623 7.04974L7.05023 5.63574L12.0002 10.5857Z"
            fill="black"
          />
        </svg>
      </div>

      {/* Image en dessous */}
      <div className="d-flex flex-column align-items-center mb-3">
        <img
          src={profil}
          alt="Profile"
          style={{
            borderRadius: "50%",
            width: "80px",
            height: "80px",
            marginBottom: "30px",
          }}
        />
        {/* <p className="orange card-kyb mb-15">KYB à compléter</p> */}
        <h2 className="title-36 bold purple">
          {userMe?.firstName} {userMe?.lastName}
        </h2>
      </div>

      {/* Une autre image en dessous */}
      <div
        className="text-center mb-3 border-r-10"
        style={{
          backgroundImage: `url(${bgPurple})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "20px 10px 20px 10px",
        }}
      >
        <p className="white title-18 bold mb-3"> Votre Oui'pass</p>
        {passCard ? (
          <div
            className="pass-card-container my-5  mw-50 mh-"
            dangerouslySetInnerHTML={{ __html: passCard }}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
