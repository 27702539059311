// Assuming the existence of "../contexts/Web3AuthContext" with proper TypeScript typing
import { fetchAuthSession } from "aws-amplify/auth";
import useSocialSignIn from "../hooks/useSocialSign";
import useMetamaskSignIn from "./useMetamaskSign";
import useWeb3Actions from "./useWeb3Actions";

import { useWeb3Auth } from "../contexts/Web3AuthContext";

const useAbstractConnect = () => {
  const { signInWithGoogle } = useSocialSignIn();
  const { logout } = useWeb3Actions();
  const { signInWithMetamask } = useMetamaskSignIn();
  const { setAuthSession } = useWeb3Auth();

  const connectWithGoogle = async () => {
    try {
      logout();
      await signInWithGoogle();
    } catch (error) {
      console.error("Failed to connect with Google:", error);
    }
  };

  const connectWithMetamask = async () => {
    try {
      logout();
      await signInWithMetamask();
      const auth = await fetchAuthSession();
      setAuthSession(auth);
    } catch (error) {
      logout();
      console.error("Failed to connect with mm:", error);
    }
  };

  const fullLogout = async () => {
    await logout();
    window.location.href = "/";
  };

  return {
    connectWithGoogle,
    connectWithMetamask,
    fullLogout,
  };
};

export default useAbstractConnect;
