

export const handleRedirectVehicle = (url: string) => {
    const localStorageObject: any = {}

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key) localStorageObject[key] = localStorage.getItem(key)
    }
    const tokens = localStorageObject
    const targetWindow = window.open(`${url}`, "_blank")
    if (!targetWindow) {
        console.error("Failed to open target window.")
        return
    } else {
        console.log("Target window opened:", targetWindow)
        setTimeout(() => {
            if (targetWindow) {
                targetWindow.postMessage(tokens, `${url}`)
            }
        }, 3000)
    }
    // Send tokens securely
}