import { useState } from "react"
import { signIn, signUp } from "aws-amplify/auth"
import { getRandomString } from "./useMetamaskSign"

interface UseSmsSignInReturn {
  signInWithPhoneNumber: (phoneNumber: string) => Promise<void>
  error: Error | null
}

const useSmsSignIn = (): UseSmsSignInReturn => {
  const [error, setError] = useState<Error | null>(null)

  const signInWithPhoneNumber = async (phoneNumber: string) => {
    const passw = getRandomString(30)

    try {
      await signIn({
        username: phoneNumber,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })
        .then(async (res) => {
          console.log("SUCCESSFULLY SIGNED IN")
          console.log(res)
          return res
        })
        .catch(async (err: Error) => {
          console.log(err)
          if (err.message.includes("User does not exist")) {
            const params = {
              username: phoneNumber,
              password: passw,
              options: {
                userAttributes: {
                  "custom:authMethod": "sms",
                  phone_number: phoneNumber,
                },
              },
            }
            await signUp(params)
              .then((res) => console.log("ok cool", res))
              .catch((err) => console.log(err))
            return signInWithPhoneNumber(phoneNumber)
          } else {
            throw err
          }
        })
    } catch (err: any) {
      setError(err)
      console.error("Error initiating SMS sign in:", err)
    }
  }

  return {
    signInWithPhoneNumber,
    error,
  }
}

export default useSmsSignIn
