import React, { useEffect, useState } from "react";
import "../../styles/components/ExclusivePassPage.css";
import logo from "../../assets/mint/logo-mint.png";
import valide from "../../assets/mint/icons/valide.png";
import star from "../../assets/mint/icons/star.png";
import Loader from "../../components/Mint/Loader";
import { getPassCard, mintPass } from "../../api/services/user";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";

const PassReadyPage = ({ passCard }: { passCard: string | undefined }) => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100  bg-image text-white">
      <div className="container md:ml-48 ">
        <div className="row text-center text-md-start">
          {/* Column for the logo and text */}
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-start mt-3">
            <div className="d-flex flex-column align-items-center align-items-md-start">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid mb-4 align-self-md-start"
              />
              <h1 className="text-header">Votre pass est prêt</h1>
            </div>
            <p className="text-paragraph my-1">
              Félicitations, votre pass partenaire a été créé avec succès.
            </p>

            {/* Feature blocks only visible on medium and larger screens */}
            <div className="row d-none d-md-flex">
              <div className="col-md-6 mb-4">
                <div className="d-flex align-items-start">
                  <div className="feature-block">
                    <img
                      src={valide}
                      alt="valide"
                      className="img-fluid mt-5 mb-2"
                    />
                    <h4 className="feature-title">
                      Statut de Partenaire Certifié
                    </h4>
                    <p className="feature-text">
                      Le pass atteste que vous êtes un partenaire officiel de
                      Ouibild, renforçant votre crédibilité auprès de vos
                      clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="d-flex align-items-start">
                  <div className="ms-3 feature-block">
                    <img
                      src={star}
                      alt="valide"
                      className="img-fluid mt-5 mb-2"
                    />
                    <h4 className="feature-title">
                      Accès à des Avantages Exclusifs
                    </h4>
                    <p className="feature-text">
                      Découvrez des avantages spéciaux tout au long de votre
                      aventure avec Ouibild, réservés exclusivement aux
                      détenteurs.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <a
              href="/dashboard"
              className="btn btn-light btn-dashboard py-3 mt-4 rounded-pill"
            >
              <p className="purple">Accéder à mon dashboard</p>
            </a>
          </div>

          <div className="col-md-6 d-flex align-items-center justify-content-center">
            {passCard ? (
              <div
                className="my-5 img-fluid"
                dangerouslySetInnerHTML={{ __html: passCard }}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ExclusivePassPage = () => {
  const [passCard, setPassCard] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { userMe } = useWeb3Auth();
  const [isPassReady, setIsPassReady] = useState(false);

  useEffect(() => {
    if (userMe && userMe.id) {
      setIsLoading(true);
      getPassCard(userMe.id).then((res) => {
        setIsLoading(false);
        if (res) {
          setPassCard(res);
          setIsPassReady(true); // Automatically set pass as ready when data is fetched
        }
      });
    }
  }, [userMe]);

  const handleButtonClick = () => {
    setIsLoading(true);
    mintPass().then(() => {
      setIsLoading(false);
      setIsPassReady(true); // Set pass ready after minting
    });
  };

  // If minting or loading the pass
  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100 bg-image text-white">
        <div className="text-center d-flex flex-column align-items-center justify-content-center ">
          <div className="mb-5">
            <Loader />
          </div>
          <img src={logo} alt="Logo" className="img-fluid mb-2 " />
          <div className="d-flex justify-content-center">
            <h2 className="text-header text-center">
              Génération de votre pass en cours...
            </h2>
          </div>
          <p className="text-paragraph text-center mt-4">
            Veuillez patienter pendant que nous générons votre pass.
          </p>
        </div>
      </div>
    );
  }

  // If the pass is ready and loaded
  if (isPassReady && passCard) {
    return <PassReadyPage passCard={passCard} />;
  }

  // Default view before pass is ready
  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-image text-white">
      <div className="text-center">
        <div className="d-flex justify-content-center">
          <img src={logo} alt="Logo" className="mb-3" />
        </div>

        <div className="d-flex justify-content-center">
          <h1 className="text-header mb-4">
            Obtenez votre pass exclusif offert par Ouibild
          </h1>
        </div>
        <div className="d-flex justify-content-center">
          <p className="text-paragraph">
            En tant que partenaire certifié Ouibild, vous recevrez un pass
            numérique unique. Ce pass certifie votre statut de partenaire
            officiel et vous donne accès à des avantages exclusifs sur notre
            plateforme.
          </p>
        </div>
        <button
          className="btn btn-light btn-custom rounded-pill py-3 mt-5"
          onClick={handleButtonClick}
        >
          <p className="purple">Créer mon Oui’Pass</p>
        </button>
      </div>
    </div>
  );
};

export default ExclusivePassPage;
