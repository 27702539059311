import { useState } from "react";
import axios, { AxiosError } from "axios";
import { API_URL } from "../config/api";
import { toast } from "react-toastify";

const useNewsletterPost = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const postNewsletter = async (email: string) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/api/newsletter`, { email }, {
      });

      setData(response.data); 
      setLoading(false);
      console.log(response.data);
    } catch (err: AxiosError | any) {
      setError(err.message || "An error occurred");
      toast.error('Une erreur est survenue veuillez réessayer plus tard');
      setLoading(false);
    }
  };

  return { data, error, loading, postNewsletter };
};

export default useNewsletterPost;
