import { toast } from "react-toastify";
import { FormUser } from "../../types/user";

// Utility function to validate email format
const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// // Utility function to validate phone number format (only digits)
// const isValidPhoneNumber = (phoneNumber: string): boolean => {
//   const phoneNumberRegex = /^\d+$/;
//   return phoneNumberRegex.test(phoneNumber);
// };

export const validateStep = (
  step: number,
  informationsUser: FormUser
): boolean => {
  switch (step) {
    case 4:
      if (!informationsUser.companyName) {
        toast.error("Veuillez saisir le nom de votre entreprise.");
        return false;
      }
      if (!informationsUser.experienceYears) {
        toast.error("Veuillez nous faire part de votre expérience.");
        return false;
      }
      if (!informationsUser.position) {
        toast.error("Veuillez nous faire part de votre position.");
        return false;
      }
      {
        /* 
      if (!informationsUser.certifications) {
        toast.error("Veuillez nous faire part de vos certifications.");
        console.log("Please provide your certifications.");
        return false;
      }
            */
      }
      break;

    // case 5:

    //   if (!informationsUser.objective) {
    //     toast.error("Veuillez spécifier votre objectif.");
    //     console.log("Please specify your objective.");
    //     return false;
    //   }
    //   break;
    case 5:
      if (informationsUser.challenges.length === 0) {
        toast.error("Veuillez spécifier votre défi.");
        return false;
      }
      break;
    case 6:
      console.log("investedInTokens", informationsUser.investedInTokens);
      if (informationsUser.investedInTokens === null) {
        toast.error("Veuillez spécifier si vous avez investi dans des tokens.");
        console.log("Please specify if you have invested in tokens.");
        return false;
      }
      if (informationsUser.wantsGuide === null) {
        toast.error("Veuillez spécifier si vous souhaitez recevoir une guide.");
        console.log("Please specify if you want to receive a guide.");
        return false;
      }
      break;

    default:
      return true;
  }

  return true;
};
