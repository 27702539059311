import { useEffect, useState } from "react";
import "../../../src/styles/components/Dashboard.css";
import backgroundImage2 from "../../assets/dashboard/Rectangle2481.png";
import backgroundImage3 from "../../assets/dashboard/Rectangle2482.png";
import BgCard from "../../assets/background/backgroundCard.png";

interface GridCardProps {
  topRightText: string;
  number: string | number;
  label: string;
  svgIcon: React.ReactNode; // SVG Icon prop
}
interface CardProps {
  topRightText: string;
  number: string | number;
  title: string;
  description: string;
  buttonText: string;
  bgImg: string;
}

export const SecondCard: React.FC<CardProps> = ({
  topRightText,
  number,
  title,
  description,
  buttonText,
  bgImg,
}) => {
  return (
    <div className="relative flex flex-col p-4 border-[0.3px] border-black rounded-lg bg-white flex-grow ">
      {/* Top section with SVG and TopRightText */}
      <div className="flex justify-between items-start w-full mb-3 z-10">
        <div className="p-2 rounded-[3px] bg-[#DBD8FF]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        </div>
        <div className="text-right text-[#454545] text-xs z-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <path
            d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
            fill="black"
          />
        </svg>
        </div>
      </div>

      {/* Number and title */}
      <div className="text-[#3500A5] font-manrope text-[36px] font-bold leading-normal mb-2 z-10">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <path
            d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="text-[#454545] font-manrope text-[14px] font-semibold leading-normal mb-2 z-10">
        {title}
      </div>

      {/* Bottom section with description and button */}
      <div className="mt-auto z-10">
        <div className=" rounded-b-lg p-4 text-sm md:w-4/6 mx-auto">
          <p className="text-[#3500A5] text-center mb-4">{description}</p>
          <div className="flex justify-center">
            <a
              href="/products"
              className="btn bg-[#3500A5] text-white rounded-full py-2 px-4"
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>

      {/* <img
        src={backgroundImage}
        alt="Background"
        className="absolute inset-0 bottom-0 "
      /> */}

      {/* Background Image at Bottom */}
      <div
        className="absolute z-0 inset-x-0 bottom-0"
        // style={{
        //   backgroundImage: `url(${backgroundImage})`,
        //   backgroundSize: "contain",
        // }}
      >
        <img src={bgImg} alt="Background" className="w-full rounded-lg" />
      </div>
    </div>
  );
};

export const FirstCard: React.FC<GridCardProps> = ({
  topRightText,
  number,
  label,
  svgIcon,
}) => {
  return (
    <div className="flex flex-col p-3 border-[0.3px] border-black rounded-lg bg-white flex-grow mb-6 ">
      <div className="flex justify-between items-start w-full mb-3">
        {/* SVG Icon on the left */}
        <div className="p-[10px] rounded-lg bg-[#DBD8FF]">{svgIcon}</div>

        {/* Top Right Text on the right */}
        <div className="text-right text-[#454545] text-xs mt-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <path
            d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
            fill="black"
          />
        </svg>
        </div>
      </div>

      {/* Number and Label */}
      <div className="text-[#3500A5] font-manrope text-[36px] font-bold leading-normal py-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <path
            d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="text-[#4800E0] font-manrope text-[14px] font-semibold leading-normal">
        {label}
      </div>
    </div>
  );
};

interface ThirdCardProps {
  title: string;
  description: string;
  buttonText: string;
}

export const ThirdCard: React.FC<ThirdCardProps> = ({
  title,
  description,
  buttonText,
}) => {
  const [bgImage, setBgImage] = useState(backgroundImage2); // Set default background image

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setBgImage(backgroundImage3); // Use mobile background image for smaller screens
      } else {
        setBgImage(backgroundImage2); // Use default background image for larger screens
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative flex flex-col border-[0.3px] border-black rounded-lg h-[442px] max-w-[700px] ">
      {/* Top section with SVG and title */}
      <div className="flex items-start w-full z-10  p-20">
        <div className="p-2 rounded-[3px] bg-[#DBD8FF]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#3500A5"
              d="M12 2L2 7v10l10 5 10-5V7L12 2zM4 9.25L12 13l8-3.75V17l-8 4-8-4V9.25z"
            />
          </svg>
        </div>
        <div className=" text-black text-base font-bold ml-2 mt-2">{title}</div>
      </div>

      {/* Bottom section with description and button */}
      <div className="flex flex-col justify-center items-center relative z-10 p-5 text-center md:w-2/4 mx-auto md:mt-10 ">
        <p className=" mb-4 text-[#3500A5]">{description}</p>
        <a
          href="/products"
          className="btn bg-[#4800E0] text-white rounded-full py-2 px-4"
        >
          {buttonText}
        </a>
      </div>

      {/* Background Image */}
      <div
        className="absolute z-0 inset-x-0 bottom-0 "
        // style={{
        //   backgroundImage: `url(${backgroundImage})`,
        //   backgroundSize: "contain",
        // }}
      >
        <img src={bgImage} alt="Background" className="w-full rounded-lg" />
      </div>
    </div>
  );
};

interface FourCardProps {
  title: string;
  description: string;
  buttonText: string;
  bgImg: string;
}

export const FourCard: React.FC<FourCardProps> = ({
  title,
  description,
  buttonText,
  bgImg,
}) => {
  return (
    <div
      className="card-revenu p-0"
      style={{
        backgroundImage: `url(${BgCard})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "20px",
      }}
    >
      <img
        src={bgImg}
        className="img-fluid"
        alt="Contenu visuel carte 1"
        style={{
          width: "100%",
        }}
      />
      <div style={{ padding: "0px 20px 40px 20px" }} className="white gap-30">
        <h2 className="title-24 bold ">{title}</h2>
        <p className="title-16 bold-400 my-3" style={{ opacity: "0.8" }}>
          {description}
        </p>
        <div className="flex ">
          <a
            href="/products"
            className="btn bg-[#4800E0] text-white rounded-full py-2 px-4"
          >
            {buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};
