import React, { useEffect, useState, useCallback } from "react";
import { SignUpFormStep1 } from "../components/signup/SignUpFormStep1";
import { SignUpFormStep2 } from "../components/signup/SignUpFormStep2";
import { SignUpFormStep3 } from "../components/signup/SignUpFormStep3";
import { SignUpFormStep5 } from "../components/signup/SignUpFormStep5";
import { SignUpFormStep6 } from "../components/signup/SignUpFormStep6";
import { SignUpFormStep7 } from "../components/signup/SignUpFormStep7";
import { useWeb3Auth } from "../authent/contexts/Web3AuthContext";
import { validateStep } from "../components/signup/validateStep";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormUser } from "../types/user";
import EmailVerification from "../components/signup/SignUpValidationCode";
import { DataFormProvider } from "../hook/DataFormProvider";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading/Loading";
import BgProgress from "../assets/background/backgroundProgress.png";
import useAbstractConnect from "../authent/hooks/useAbstractConnect";

export const Signup: React.FC = () => {
  const [step, setStep] = useState(0);
  const { userMe } = useWeb3Auth();
  const [loading, setLoading] = useState(true);
  const { fullLogout } = useAbstractConnect();
  const navigate = useNavigate();

  const [informationsUser, setInformationsUser] = useState<FormUser>({
    companyName: "",
    position: "",
    experienceYears: "ONE_TO_FIVE",
    certifications: "",
    challenges: [],
    otherChallenge: "",
    investedInTokens: null,
    wantsGuide: null,
  });

  const handleNextStep = () => {
    if (validateStep(step, informationsUser)) {
      setStep((prevStep: number) => prevStep + 1);
    }
  };

  const handlePrevStep = () => {
    setStep((prevStep: number) => prevStep - 1);
  };

  const checkAuthent = useCallback(async () => {
    try {
      if (!userMe || Object.keys(userMe).length === 0 || userMe === undefined) {
        console.log("User not authenticated, redirecting to step 1");
        setStep(1);
        return;
      }

      if (userMe.form) {
        navigate("/dashboard");
        return;
      }

      if (!userMe.firstName && !userMe.lastName) {
        console.log("User authenticated with email only:", userMe);
        setStep(3);
      } else if (userMe.firstName && userMe.lastName) {
        console.log(
          `User authenticated with name: ${userMe.firstName} ${userMe.lastName}`
        );
        setStep(4);
      } else {
        setStep(2);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setStep(0);
    } finally {
      setLoading(false);
    }
  }, [userMe, navigate, setStep, setLoading]);

  useEffect(() => {
    if (userMe !== null) {
      checkAuthent();
    }
  }, [userMe, checkAuthent]);

  return (
    <div
      style={{
        backgroundImage: `url(${BgProgress})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <ToastContainer />

      <div
        style={{
          transformOrigin: "top center",
          width: "100%",
        }}
      >
        <DataFormProvider>
          {loading && <Loading />}
          {!loading && step === 1 && (
            <SignUpFormStep1
              setStep={setStep}
              setInformationsUser={setInformationsUser}
              informationsUser={informationsUser}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            />
          )}
          {!loading && step === 2 && <EmailVerification />}

          {!loading && step === 3 && (
            <SignUpFormStep2
              setStep={setStep}
              setInformationsUser={setInformationsUser}
              informationsUser={informationsUser}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            />
          )}
          {!loading && step === 4 && (
            <SignUpFormStep3
              setStep={setStep}
              setInformationsUser={setInformationsUser}
              informationsUser={informationsUser}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            />
          )}
          {!loading && step === 5 && (
            <SignUpFormStep5
              setStep={setStep}
              setInformationsUser={setInformationsUser}
              informationsUser={informationsUser}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            />
          )}
          {!loading && step === 6 && (
            <SignUpFormStep6
              setStep={setStep}
              setInformationsUser={setInformationsUser}
              informationsUser={informationsUser}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            />
          )}
          {!loading && step === 7 && (
            <SignUpFormStep7
              setStep={setStep}
              setInformationsUser={setInformationsUser}
              informationsUser={informationsUser}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            />
          )}
        </DataFormProvider>
      </div>
    </div>
  );
};
