import LogoOuibild from "../../assets/logo/logoOuibildWhite.svg";

export const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 bg-footer">
      <div className="container">
        {/* Line 1: Logo */}
        <div
          className="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 bg-purple border-r-10"
          style={{ padding: "40px 30px" }}
        >
          {/* h2 dans un col-4 */}
          <div className="col-md-6">
            <h2 className="title-36 bold  mb-md-0 me-md-3 mb-30 text-md-start white">
              Vous avez besoin d’informations ?
            </h2>
          </div>

          {/* p dans un col-6 */}
          <div className="col-md-3 col-12 d-flex justify-content-md-center justify-content-start">
            <a
              className="button-rounded-white"
              href="mailto:contact@ouibild.io"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V19H20V7.3L12 14.5L2 5.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20.007ZM4.434 5L12 11.81L19.566 5H4.434ZM0 15H8V17H0V15ZM0 10H5V12H0V10Z"
                  fill="currentColor"
                />
              </svg>
              contact@ouibild.io
            </a>
          </div>
        </div>

        {/* Line 2: Navigation Links */}
        <div className="row mb-4 white mt-70 justify-content-between">
          <div
            className="col-8 col-lg-3 d-flex flex-column"
            style={{ marginRight: "100px" }}
          >
            <img
              src={LogoOuibild}
              alt="logo Ouibild"
              style={{ width: "120px" }}
              className="mb-30"
            />
            <p className="title-14 bold-500 white mb-12">
              Découvrez et proposez des opportunités d'investissement innovantes
              et exclusives pour vos clients, sécurisées par la tokenisation.
            </p>
          </div>
          <div className="col-8 col-lg-2 d-flex flex-column ">
            <h3 className="title-24 bold mb-30">Navigation</h3>
            <a href="/dashboard" className="link-footer  mb-12">
              Dashboard
            </a>
            <a href="/products" className="link-footer  mb-12">
              Projets disponibles
            </a>
            <a href="/client" className="link-footer   mb-12">
              Mes clients
            </a>
            <a href="/dashboard" className="link-footer mb-12">
              Profil
            </a>
          </div>
          <div className="col-8 col-lg-2 d-flex flex-column">
            <h3 className="title-24 bold mb-30">Légal</h3>
            <a href="/legal" className="link-footer mb-12">
              Mentions légales
            </a>
          </div>
          <div className="col-8 col-lg-2 d-flex flex-column">
            <h3 className="title-24 bold mb-30">Réseaux sociaux</h3>
            <a
              href="https://twitter.com/Ouibild"
              target="_blank"
              className="link-footer white mb-12"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://www.linkedin.com/company/ouibild/posts/?feedView=all"
              target="_blank"
              className="link-footer mb-12"
              rel="noreferrer"
            >
              Linkedin
            </a>
          </div>
        </div>

        <hr className="white w-100" style={{ marginBlock: "40px" }}></hr>

        <div className="row mt-4 text-center" style={{ marginBottom: "40px" }}>
          <h2 className="white bold title-24 mb-4"> Important</h2>
          <p className="title-16 white bold-500 opacity-05">
            <span className="bold-800 ">
              Ouibild est une solution technique permettant la tokenisation
              d’actifs du monde réel. <br />
              Ouibild n’est pas une plateforme de financement participatif
              régulée par l’AMF. <br />{" "}
            </span>
            Les clients utilisant notre solution nous ont autorisés à afficher
            les actifs qu’ils tokenisent à titre d’exemple de clients
            accompagnés. <br /> <br />
            Les données présentés à ce jour, sont fictives et sont uniquement
            utilisées à titre d’exemple.Les projets présentés comportent un
            risque de perte partielle ou totale en capital ainsi qu'un risque
            d'illiquidité. <br /> <br />
            Les utilisateurs du Dashboard Ouibild peuvent uniquement accéder au
            catalogue d’actifs tokénisés via la solution technique Ouibild. Les
            utilisateurs ne peuvent en aucun cas investir directement via leur
            Dashboard Ouibild. <br /> <br />
            Chaque projet présenté dans le Dashboard Ouibild Catalogue renvoie
            vers le site du porteur de projet.
          </p>
        </div>

        <div className="row" style={{ marginBottom: "40px" }}>
          <p className="purple-footer opacity-05 title-16 bold-500 text-center">
            ©2023 Ouibild - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};
