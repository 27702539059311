import { NavProduct } from "../../components/Navbar/navProduct";
import bgPurple from "../../assets/products/rectanglePurple.png";
import {
  FirstStats,
  FourStats,
  SecondStats,
  ThirdStats,
} from "../../components/Dashboard/Grid";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";
import { Footer } from "../../components/Footer/footer";

export const Dashboard = () => {
  const { userMe } = useWeb3Auth();

  return (
    <div>
      <NavProduct />

      {/* Background Product Section */}
      <div
        style={{
          height: "500px",
          backgroundImage: `url(${bgPurple})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" data-aos-delay={300}>
          <div className="row mx-[20px] md:mx-[140px]">
            <div
              className=" d-flex flex-column mt-[90px] md:mt-[200px]"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <h3
                className="title-24 bold-500 white"
                style={{ opacity: "0.6" }}
              >
                Dashboard
              </h3>
              <h2 className="title-50 bold white md:pb-4">
                Bonjour {userMe?.firstName}
              </h2>
              <p
                className="title-16 white"
                style={{ opacity: "0.6", width: "100%" }}
              >
                Retrouvez l’ensemble de vos performances directement depuis
                votre dashboard
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bottom-60 md:bottom-20 mx-3 md:mx-0">
        <FirstStats />
        <SecondStats />
        <ThirdStats />
        <FourStats />
      </div>
      <Footer />
    </div>
  );
};
