import { NavProduct } from "../../components/Navbar/navProduct";
import productData from "../../config/productData.json";
import { numberWithSpaces } from "../../utils/numberSpace";
import bgPurple from "../../assets/products/rectanglePurple.png";
import { useState } from "react";
import { Footer } from "../../components/Footer/footer";
import { handleRedirectVehicle } from "../../hook/useRedirect";

export const Product = () => {
  const [isPrevenu, setIsPrevenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Tous");

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const filteredProjects = productData.filter((product) => {
    if (selectedFilter === "Tous") return true;
    if (selectedFilter === "En cours") return false;
    if (selectedFilter === "Terminés") return false;
    return true;
  });

  return (
    <div>
      <NavProduct />

      {/* Background Product Section */}
      <div
        style={{
          height: "500px",
          backgroundImage: `url(${bgPurple})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="container"
          data-aos="fade-right"
          data-aos-delay={300}
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <div
            className="row align-items-stretch"
            style={{ marginBottom: "70px" }}
          >
            <div
              className="col-lg-6 col-12 d-flex flex-column gap-20 justify-content-center mt-50-r"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <h2 className="title-50 bold white">
                Découvrez les clients de l’écosystème
              </h2>
              <p className="title-16 white" style={{ opacity: "0.6" }}>
                Retrouvez l’ensemble de vos performances directement depuis
                votre dashboard
              </p>
            </div>

            <div
              className="col-lg-6 col-12 d-flex justify-content-center align-items-center mt-50-r"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="card-filter  d-flex justify-content-around align-items-center gap-3">
                {/* Tous les projets */}
                <button
                  className={`p-20 ${
                    selectedFilter === "Tous"
                      ? "button-card-filter"
                      : "button-rounded-transparent"
                  }`}
                  onClick={() => handleFilterClick("Tous")}
                >
                  <span className="d-block d-lg-none">Tous</span>{" "}
                  <span className="d-none d-lg-block">Tous les projets</span>{" "}
                </button>

                {/* Projet en cours */}
                <button
                  className={`p-20 ${
                    selectedFilter === "En cours"
                      ? "button-card-filter"
                      : "button-rounded-transparent"
                  }`}
                  disabled
                  onClick={() => handleFilterClick("En cours")}
                >
                  <span className="d-block d-lg-none">en cours</span>{" "}
                  <span className="d-none d-lg-block ">Projets en cours</span>{" "}
                </button>

                {/* Projets terminés */}
                <button
                  className={`p-20 ${
                    selectedFilter === "Terminés"
                      ? "button-card-filter"
                      : "button-rounded-transparent"
                  }`}
                  disabled
                  onClick={() => handleFilterClick("Terminés")}
                >
                  <span className="d-block d-lg-none">terminés</span>{" "}
                  <span className="d-none d-lg-block">Projets terminés</span>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <section className="section-btm-100 ">
          <div className="container" style={{ marginTop: "-50px" }}>
            <div className="row justify-content-center">
              <div
                className="row g-3 mt-70"
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={400}
                data-aos-easing="ease"
              >
                <div className="container" style={{ marginTop: "-50px" }}>
                  <div className="row justify-content-center">
                    <div
                      className="row g-3 mt-70"
                      data-aos="fade-up"
                      data-aos-duration={700}
                      data-aos-delay={400}
                      data-aos-easing="ease"
                    >
                      {filteredProjects.map((product, index) => (
                        <div
                          key={index}
                          className="col-12 col-md-12 col-lg-6 mx-auto m-1 p-1"
                        >
                          {/* Ajout de la condition pour entourer la card d'un lien si product.link existe */}
                          {product.link ? (
                            <a
                              onClick={() =>
                                handleRedirectVehicle(product.link)
                              }
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                className="card-custom position-relative d-flex flex-column justify-content-between"
                                style={{
                                  backgroundImage: `url(${product.image})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  overflow: "hidden",
                                }}
                                data-aos="fade-up"
                                data-aos-delay={300 + index * 100}
                                data-aos-duration={400}
                              >
                                {!product.available && (
                                  <div
                                    className="backdrop-6 position-absolute"
                                    style={{
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      zIndex: 1,
                                    }}
                                  ></div>
                                )}

                                <div
                                  className="text-container p-20-t-30 backdrop-6"
                                  style={{ zIndex: 2 }}
                                >
                                  <h3 className="title-16 bold-400 text-white">
                                    {product.Categorie}
                                  </h3>
                                  <p className="title-30 bold text-white">
                                    {product.title}
                                  </p>
                                  <p className="title-16 bold-400 text-white">
                                    {product.subtitle}
                                  </p>
                                </div>

                                {product.available ? (
                                  <div
                                    className="info-section mt-auto"
                                    style={{ zIndex: 2 }}
                                  >
                                    <div className="row gx-3 gy-3">
                                      <div
                                        className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                                        style={{ gap: "15px" }}
                                      >
                                        <p className="mb-0 title-10 bold-400">
                                          Objectif
                                        </p>
                                        <p className="mb-0 title-16 bold">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="18"
                                            viewBox="0 0 19 18"
                                            fill="none"
                                          >
                                            <path
                                              d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </p>
                                      </div>

                                      <div
                                        className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                                        style={{ gap: "15px" }}
                                      >
                                        <p className="mb-0 title-10 bold">
                                          Invest minimum
                                        </p>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="19"
                                          height="18"
                                          viewBox="0 0 19 18"
                                          fill="none"
                                        >
                                          <path
                                            d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </div>

                                      <div
                                        className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                                        style={{ gap: "15px" }}
                                      >
                                        <p className="mb-0 title-10 bold-400">
                                          Rendement
                                        </p>
                                        <p className="mb-0 title-16 bold">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="18"
                                            viewBox="0 0 19 18"
                                            fill="none"
                                          >
                                            <path
                                              d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </p>
                                      </div>
                                      <div className="w-100 mt-3">
                                        <div
                                          className="progress"
                                          style={{ height: "5px" }}
                                        >
                                          <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{
                                              width: `${product.progress}%`,
                                              height: "5px",
                                            }}
                                            aria-valuenow={product.progress}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="info-section mt-auto"
                                    style={{
                                      zIndex: 2,
                                      background: "rgba(255, 255, 255, 0.11)",
                                    }}
                                  >
                                    <div className="row gx-3 gy-3 d-flex justify-content-between align-items-center">
                                      <p className="title-24 bold white mb-0 w-auto">
                                        Sortie prochaine
                                      </p>

                                      <div
                                        className="card-prevenu d-flex align-items-center w-auto"
                                        onClick={() => setIsPrevenu(true)}
                                      >
                                        {isPrevenu ? (
                                          <>
                                            <span className="text-white me-2">
                                              Prévenu
                                            </span>
                                            {/* Votre SVG non modifié */}
                                          </>
                                        ) : (
                                          <>
                                            <span className="text-white me-2">
                                              Soyez prévenu
                                            </span>
                                            {/* Votre SVG non modifié */}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </a>
                          ) : (
                            <div
                              className="card-custom position-relative d-flex flex-column justify-content-between"
                              style={{
                                backgroundImage: `url(${product.image})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                overflow: "hidden",
                              }}
                              data-aos="fade-up"
                              data-aos-delay={300 + index * 100}
                              data-aos-duration={400}
                            >
                              {!product.available && (
                                <div
                                  className="backdrop-6 position-absolute"
                                  style={{
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 1,
                                  }}
                                ></div>
                              )}

                              <div
                                className="text-container p-20-t-30 backdrop-6"
                                style={{ zIndex: 2 }}
                              >
                                <h3 className="title-16 bold-400 text-white">
                                  {product.Categorie}
                                </h3>
                                <p className="title-30 bold text-white">
                                  {product.title}
                                </p>
                                <p className="title-16 bold-400 text-white">
                                  {product.subtitle}
                                </p>
                              </div>

                              {product.available ? (
                                <div
                                  className="info-section mt-auto"
                                  style={{ zIndex: 2 }}
                                >
                                  <div className="row gx-3 gy-3">
                                    <div
                                      className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                                      style={{ gap: "15px" }}
                                    >
                                      <p className="mb-0 title-10 bold-400">
                                        Objectif
                                      </p>
                                      <p className="mb-0 title-16 bold">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="19"
                                          height="18"
                                          viewBox="0 0 19 18"
                                          fill="none"
                                        >
                                          <path
                                            d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </p>
                                    </div>

                                    <div
                                      className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                                      style={{ gap: "15px" }}
                                    >
                                      <p className="mb-0 title-10 bold">
                                        Invest minimum
                                      </p>
                                      {/* Votre SVG non modifié */}
                                    </div>

                                    <div
                                      className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                                      style={{ gap: "15px" }}
                                    >
                                      <p className="mb-0 title-10 bold-400">
                                        Rendement
                                      </p>
                                      <p className="mb-0 title-16 bold">
                                        {/* Votre SVG non modifié */}
                                      </p>
                                    </div>
                                    <div className="w-100 mt-3">
                                      <div
                                        className="progress"
                                        style={{ height: "5px" }}
                                      >
                                        <div
                                          className="progress-bar bg-success"
                                          role="progressbar"
                                          style={{
                                            width: `${product.progress}%`,
                                            height: "5px",
                                          }}
                                          aria-valuenow={product.progress}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="info-section mt-auto"
                                  style={{
                                    zIndex: 2,
                                    background: "rgba(255, 255, 255, 0.11)",
                                  }}
                                >
                                  <div className="row gx-3 gy-3 d-flex justify-content-between align-items-center">
                                    <p className="title-24 bold white mb-0 w-auto">
                                      Sortie prochaine
                                    </p>

                                    <div
                                      className="card-prevenu d-flex align-items-center w-auto"
                                      onClick={() => setIsPrevenu(true)}
                                    >
                                      {isPrevenu ? (
                                        <>
                                          <span className="text-white me-2">
                                            Prévenu
                                          </span>
                                          {/* Votre SVG non modifié */}
                                        </>
                                      ) : (
                                        <>
                                          <span className="text-white me-2">
                                            Soyez prévenu
                                          </span>
                                          {/* Votre SVG non modifié */}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};
