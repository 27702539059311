import React, { useRef, useState } from "react";
import BgImg from "../../assets/background/bg.jpg";
import ImmoImg from "../../assets/Home/scrollableCards/immoImg.png";
import StayImg from "../../assets/Home/scrollableCards/stay2Img.png";
import ForestImg from "../../assets/Home/scrollableCards/forestImg.png";
import CadreImg from "../../assets/Home/scrollableCards/cadreImg.png";
import BlueImg from "../../assets/Home/scrollableCards/blueImg.png";

const ScrollableCards = () => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  // Fonction pour faire défiler horizontalement
  const scroll = (direction: "left" | "right") => {
    const container = scrollableContainerRef.current;
    const scrollAmount = 300; // Distance de défilement (ajustable)

    if (container) {
      if (direction === "left") {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }

      // Vérification de la position de défilement
      checkScrollPosition();
    }
  };

  // Fonction pour vérifier la position du défilement
  const checkScrollPosition = () => {
    const container = scrollableContainerRef.current;
    if (container) {
      setIsAtStart(container.scrollLeft === 0);
      setIsAtEnd(
        container.scrollLeft + container.offsetWidth >= container.scrollWidth
      );
    }
  };
  const cardsData = [
    {
      id: 1,
      title: "Immobilier d’exception",
      image: ImmoImg,
      style: {
        background:
          "linear-gradient(146deg, #534B4F 0%, #7A9DC6 0%, #BEC9DA 40.03%, #D5C3B7 99.72%)",
      },
    },
    {
      id: 2,
      title: "Start Up & Technologies",
      image: BlueImg,
      style: {
        background:
          "linear-gradient(140deg, #2B61F1 11.47%, #6EAAD6 46.89%, #A7D0EB 99.68%)",
      },
    },
    {
      id: 3,
      title: "Énergie & Ecologie",
      image: ForestImg,
      style: {
        background:
          "linear-gradient(140deg, #2FD858 11.47%, #95D4A5 46.89%, #17BA3F 99.68%)",
      },
    },
    {
      id: 4,
      title: "Cinéma & Divertissement",
      image: StayImg,
      style: {
        background:
          "linear-gradient(140deg, #FF5557 11.47%, #D04949 46.89%, #9D1E1E 99.68%)",
      },
    },
    {
      id: 5,
      title: "Art & Collections",
      image: CadreImg,
      style: {
        background:
          "linear-gradient(140deg, #CBA387 11.47%, #E0C9B5 46.89%, #E1C1AB 99.68%)",
      },
    },
  ];

 

  return (
    <div
      className="scrollable-container position-relative overflow-visible"
      data-aos="fade-up"
      data-aos-duration={700}
      data-aos-delay={400}
      data-aos-easing="ease"
    >
    <div
        className="row flex-nowrap gx-3 mt-4  border-r-20"
        style={{
          paddingBottom: "50px",
          gap: "30px",
          overflowX: "scroll",
          WebkitOverflowScrolling: "touch", 
        }}
        ref={scrollableContainerRef}
      >
        {cardsData.map((card) => (
          <div key={card.id} className="col-12 col-md-5 col-lg-3">
            <div
              className="card position-relative card-scrollable"
              style={{
                ...card.style,
              }}
            >
              <h2
                className="text-white mb-0"
                style={{ alignSelf: "flex-start", marginBottom: "20px" }}
              >
                {card.title}
              </h2>

              {/* L'image en position absolue */}
              <div
                className="img-container"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: "auto",
                  zIndex: -1,
                }}
              >
                <img
                  src={card.image}
                  alt="Contenu visuel carte"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Boutons Précédent et Suivant sous la ligne des cartes */}
      <div className="d-flex mt-4">
      <button
        className="card-button left me-2 d-none d-md-block"
        onClick={() => scroll("left")}
        disabled={isAtStart} // Désactiver si tout à gauche
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.828 10.9999H20V12.9999H7.828L13.192 18.3639L11.778 19.7779L4 11.9999L11.778 4.22192L13.192 5.63592L7.828 10.9999Z"
            fill="white"
          />
        </svg>
      </button>

      <button
        className="card-button right ms-2 d-none d-md-block"
        onClick={() => scroll("right")}
        disabled={isAtEnd} // Désactiver si tout à droite
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.172 10.9999L10.808 5.63592L12.222 4.22192L20 11.9999L12.222 19.7779L10.808 18.3639L16.172 12.9999H4V10.9999H16.172Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    </div>
  );
};

export default ScrollableCards;
