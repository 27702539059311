import React, { ReactNode, useEffect } from "react";

type ModalProps = {
  children: ReactNode;
  show: boolean;
};

export const CustomModal: React.FC<ModalProps> = ({ children, show }) => {
  // Ajuster le style du body quand le modal est ouvert ou fermé
  useEffect(() => {
    if (show) {
      // Désactiver le scroll sur body
      document.body.style.overflow = "hidden";
    } else {
      // Réactiver le scroll sur body
      document.body.style.overflow = "auto";
    }

    // Cleanup function pour remettre le scroll quand le component est démonté
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  if (!show) return null;

  return (
    <div
      className="modal-overlay d-flex justify-content-center min-vh-100"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", 
        zIndex: 9999, 
      }}
    >
      <div className="modal-content fade-in" style={{ backgroundColor: "white", borderRadius: "20px", padding: "20px", width: "100%", maxWidth: "500px" }}>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
