import { FormUser, User } from "../../types/user";
import { ApiService } from "../ApiService";

const userService = new ApiService();

// export const getForm = async (): Promise<User | null> => {
//   return await userService.get<User>("/form");
// };

export const updateForm = async (
  formData: Partial<FormUser>
): Promise<User | null> => {
  return await userService.post<User, Partial<FormUser>>("/form", formData);
};
