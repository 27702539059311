import { useEffect, useState } from "react";
import axios from "axios"; // Import axios for API requests
import backgroundImage from "../../assets/background/bgImg.png";
import LogoOuibild from "../../assets/logo/logoFullPurple.svg";
import { SignUpFormStep1Props } from "../../interface/SignUpFormStepPros";
import useAbstractConnect from "../../authent/hooks/useAbstractConnect";
import { useDataForm } from "../../hook/DataFormProvider";
import useEmailSignIn from "../../authent/hooks/useEmailSign";
import { toast } from "react-toastify";
import { API_URL } from "../../config/api";
import useNewsletterPost from "../../hook/useNewsletter";
import BgProgress from "../../assets/background/backgroundProgress.png";
import LogoOuibildG from "../../assets/logo/logoFullPurple.svg";
import logoOuibildWhite from "../../assets/logo/logoOuibildWhite.svg";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";

const MAILER_CHECK_API_KEY =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOWNmZDMxMjU5MGJjMjFhYjgxY2EzM2I2OTEyNjk1YjAwYzE2ZTExMDlkNDc4NjAwZWEwOTFkYzY2ZjYxOGVhNGFlYzY3MzE2ZDkyM2ZjOGIiLCJpYXQiOjE3MTg3MDM1MzQuMzMyNDAyLCJuYmYiOjE3MTg3MDM1MzQuMzMyNDA0LCJleHAiOjQ4NzQzNzcxMzQuMzI1OTMsInN1YiI6IjExMTgzNCIsInNjb3BlcyI6W119.da9hJ1zNUDsdnYmx_wLFJO8atvsOqq8eJv9A_FgNwaPC5KoG_1I-p-GIuB8LDk_Rc541EjmTuiY74t6TOM9piA";

export const SignUpFormStep1: React.FC<SignUpFormStep1Props> = ({
  handleNextStep,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { setEmailData, emailData } = useDataForm();

  const { connectWithGoogle } = useAbstractConnect();
  const { signInWithEmail } = useEmailSignIn();
  const [emailLoading, setEmailLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [emailSubmit, setEmailSubmit] = useState(false);
  const [email, setEmail] = useState("");
  const { loading } = useNewsletterPost();
  const [isNotCGP, setIsNotCGP] = useState(false);
  const { loggedIn } = useWeb3Auth();

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalVisible]);

  useEffect(() => {
    if (loggedIn === true) {
      setIsModalVisible(false);
    }
  }, [loggedIn]);

  const handleSubmit = async () => {
    if (!email) {
      toast.error("Veuillez entrer un email valide.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/api/newsletter`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Inscription réussie à la newsletter !");
        setEmailSubmit(true);
      } else {
        toast.error("Une erreur est survenue, veuillez réessayer.");
      }
    } catch (err) {
      console.error("Une erreur est survenue:", err);
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    }
  };

  // Email validation using MailerCheck
  const validateEmailWithMailerCheck = async (email: string) => {
    try {
      const response = await axios.post(
        "https://app.mailercheck.com/api/check/single",
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${MAILER_CHECK_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { status } = response.data;
      // 'deliverable' means the email is valid.
      if (status === "valid") {
        return true;
      } else if (status === "syntax_error") {
        toast.error("L'adresse e-mail est invalide.");
        return false;
      } else if (status === "mailbox_not_found") {
        toast.error("L'adresse e-mail est introuvable.");
        return false;
      }
    } catch (error) {
      setErrorMessage("Failed to verify email with MailerCheck.");
      return false;
    }
  };

  const handleInputChange = (event: any) => {
    setEmailData(event.target.value);
    setErrorMessage(null); // Reset error message when the user types
  };

  const handleStart = async () => {
    setEmailLoading(true);
    setErrorMessage(null);

    try {
      if (!emailData) {
        setErrorMessage("Veuillez saisir votre adresse e-mail.");
        setEmailLoading(false);
        return;
      }

      // Verify email with MailerCheck
      const isValidEmail = await validateEmailWithMailerCheck(emailData);

      if (!isValidEmail) {
        setEmailLoading(false);
        return;
      }

      console.log("emailData on submit: ", emailData);
      const res = await signInWithEmail(emailData);
      console.log("res", res);
      if (res === undefined) {
        toast.error("Votre adresse e-mail est déjà utilisée avec google.");
      } else {
        handleNextStep();
      }
    } catch (error) {
      setErrorMessage("Échec de la connexion. Veuillez réessayer.");
    } finally {
      setEmailLoading(false);
    }
  };

  return (
    <div>
      {isModalVisible && (
        <div className="custom-modal-overlay d-flex justify-content-center align-items-center ">
          <div className="custom-modal-content container">
            <div className="row min-h-500 ">
              {/* Partie gauche visible uniquement sur desktop */}
              <div
                className="col-lg-3 d-none d-lg-flex flex-column  align-items-center p-4 gap-30"
                style={{
                  backgroundColor: "#14082E",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
              >
                <img
                  src={logoOuibildWhite}
                  alt="Validation Icon"
                  className="validation-icon"
                />

                <h3 className="title-32 bold white text-center">
                  Bienvenue sur <span className="rose">Ouibild.io</span>
                </h3>
                <p className="white title-12 bold-600 text-center">
                  Afin de vous présenter les informations les plus adaptées à
                  votre profil d'investisseur et votre pays de résidence, nous
                  vous proposons un espace avec toute la gamme de produits
                  disponibles.
                </p>
              </div>

              {/* Partie droite */}
              <div className="col-12 col-lg-8 mx-auto d-flex flex-column justify-content-around p-4">
                {!isNotCGP ? (
                  <>
                    {/* Partie normale avant le clic sur "Je ne suis pas CGP" */}
                    <div className="d-flex justify-content-start flex-column mb-3">
                      <h2 className="title-20 bold">
                        <p className="title-25 purple bold">
                          Votre profil:{" "}
                          <span className="black">
                            {" "}
                            Professionnel en gestion de patrimoine
                          </span>
                        </p>
                      </h2>
                      <p className="title-16 bold grey">
                        Vous êtes un particulier à la recherche de conseils pour
                        gérer votre épargne ou à la recherche de conseillers
                        financiers.
                      </p>
                    </div>
                    <div className="scrollable-text d-flex flex-column flex-grow-1 mb-3">
                      <p className="title-14 bold-400">
                        <span className="purple">
                          Accès réservé aux professionnels de la gestion de
                          patrimoine
                        </span>
                        <br /> <br />
                        Le site Ouibild est exclusivement destiné aux
                        professionnels de la gestion de patrimoine (CGP) et
                        autres acteurs institutionnels. En tant que solution
                        technique, Ouibild fournit une infrastructure permettant
                        à ses partenaires de proposer leurs propres produits et
                        services d'investissement. Ouibild ne commercialise pas
                        directement de produits financiers et ne fournit pas de
                        conseils en investissement. <br /> <br />
                        Ce site a pour unique objet de fournir des informations
                        sur l'infrastructure technologique proposée par Ouibild
                        à ses partenaires. Les informations disponibles sur ce
                        site sont fournies à titre indicatif et visent à offrir
                        une vue d'ensemble des services et solutions techniques
                        que nous proposons. Elles ne constituent ni une offre
                        d'achat ou de vente, ni un conseil en investissement de
                        la part de Ouibild ou de ses partenaires. Ces
                        informations sont sujettes à modification sans préavis
                        et peuvent évoluer dans le temps. <br /> <br />
                        En accédant à ce site, vous reconnaissez être un
                        professionnel habilité à utiliser nos services. Si vous
                        n'êtes pas un professionnel de la gestion de patrimoine
                        ou si vous accédez au site à titre personnel, nous vous
                        invitons à quitter cette page immédiatement.
                      </p>
                    </div>
                    <div className="d-flex flex-column flex-lg-row justify-content-between gap-3 mt-auto">
                      <button
                        className="button-particulier p-20-30"
                        onClick={() => setIsNotCGP(true)}
                      >
                        Vous êtes un particulier ?
                      </button>
                      <button
                        onClick={() => setIsModalVisible(false)}
                        className="button-cgp p-20-30"
                      >
                        Valider
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {!emailSubmit ? (
                      <div className="d-flex flex-column align-items-center justify-content-center text-center gap-30 w-100">
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30 60C13.431 60 0 46.569 0 30C0 13.431 13.431 0 30 0C46.569 0 60 13.431 60 30C60 46.569 46.569 60 30 60ZM30 54C36.3652 54 42.4697 51.4714 46.9706 46.9706C51.4714 42.4697 54 36.3652 54 30C54 23.6348 51.4714 17.5303 46.9706 13.0294C42.4697 8.52856 36.3652 6 30 6C23.6348 6 17.5303 8.52856 13.0294 13.0294C8.52856 17.5303 6 23.6348 6 30C6 36.3652 8.52856 42.4697 13.0294 46.9706C17.5303 51.4714 23.6348 54 30 54ZM33 30H45V36H27V15H33V30Z"
                            fill="#F4A342"
                          />
                        </svg>
                        <h2 className="title-35 bold">Restez informés</h2>
                        <p className="title-16 bold-400">
                          Nous ne pouvons pas continuer votre inscription pour
                          le moment.
                        </p>
                        <div className="d-flex flex-column flex-lg-row align-items-center gap-3 justify-content-center">
                          <div className="flex-grow-1">
                            <input
                              type="email"
                              id="companyName"
                              placeholder="Entrez votre email"
                              className="form-control shadow-none input-transparent"
                              style={{ padding: "15px 20px" }}
                              value={email}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setEmail(e.target.value)}
                            />
                          </div>
                          <button
                            className="button-p-purple p-20-30 border-r-10"
                            onClick={handleSubmit}
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </>
                            ) : (
                              "S'inscrire"
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center text-center gap-30 w-100">
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30 60C13.431 60 0 46.569 0 30C0 13.431 13.431 0 30 0C46.569 0 60 13.431 60 30C60 46.569 46.569 60 30 60ZM30 54C36.3652 54 42.4697 51.4714 46.9706 46.9706C51.4714 42.4697 54 36.3652 54 30C54 23.6348 51.4714 17.5303 46.9706 13.0294C42.4697 8.52856 36.3652 6 30 6C23.6348 6 17.5303 8.52856 13.0294 13.0294C8.52856 17.5303 6 23.6348 6 30C6 36.3652 8.52856 42.4697 13.0294 46.9706C17.5303 51.4714 23.6348 54 30 54ZM27.009 42L14.28 29.271L18.522 25.029L27.009 33.516L43.977 16.545L48.222 20.787L27.009 42Z"
                            fill="#2AC584"
                          />
                        </svg>
                        <h2 className="title-35 bold">
                          Votre inscription à bien été enregistrée
                        </h2>

                        <div className="d-flex flex-column align-items-center">
                          <h3 className="purple title-16 bold text-center mb-3">
                            Retrouvez nous sur nos réseaux sociaux
                          </h3>

                          <div
                            className="d-flex align-items-center justify-content-around"
                            style={{ gap: "22px" }}
                          >
                            <a href="/" className="a-reseaux ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="currentColor"
                              >
                                <path d="M22.2125 5.65605C21.4491 5.99375 20.6395 6.21555 19.8106 6.31411C20.6839 5.79132 21.3374 4.9689 21.6493 4.00005C20.8287 4.48761 19.9305 4.83077 18.9938 5.01461C18.2031 4.17106 17.098 3.69303 15.9418 3.69434C13.6326 3.69434 11.7597 5.56661 11.7597 7.87683C11.7597 8.20458 11.7973 8.52242 11.8676 8.82909C8.39047 8.65404 5.31007 6.99005 3.24678 4.45941C2.87529 5.09767 2.68005 5.82318 2.68104 6.56167C2.68104 8.01259 3.4196 9.29324 4.54149 10.043C3.87737 10.022 3.22788 9.84264 2.64718 9.51973C2.64654 9.5373 2.64654 9.55487 2.64654 9.57148C2.64654 11.5984 4.08819 13.2892 6.00199 13.6731C5.6428 13.7703 5.27232 13.8194 4.90022 13.8191C4.62997 13.8191 4.36771 13.7942 4.11279 13.7453C4.64531 15.4065 6.18886 16.6159 8.0196 16.6491C6.53813 17.8118 4.70869 18.4426 2.82543 18.4399C2.49212 18.4402 2.15909 18.4205 1.82812 18.3811C3.74004 19.6102 5.96552 20.2625 8.23842 20.2601C15.9316 20.2601 20.138 13.8875 20.138 8.36111C20.138 8.1803 20.1336 7.99886 20.1256 7.81997C20.9443 7.22845 21.651 6.49567 22.2125 5.65605Z"></path>
                              </svg>
                            </a>
                            <a href="/" className="a-reseaux ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="currentColor"
                              >
                                <path d="M18.3362 18.339H15.6707V14.1622C15.6707 13.1662 15.6505 11.8845 14.2817 11.8845C12.892 11.8845 12.6797 12.9683 12.6797 14.0887V18.339H10.0142V9.75H12.5747V10.9207H12.6092C12.967 10.2457 13.837 9.53325 15.1367 9.53325C17.8375 9.53325 18.337 11.3108 18.337 13.6245V18.339H18.3362ZM7.00373 8.57475C6.14573 8.57475 5.45648 7.88025 5.45648 7.026C5.45648 6.1725 6.14648 5.47875 7.00373 5.47875C7.85873 5.47875 8.55173 6.1725 8.55173 7.026C8.55173 7.88025 7.85798 8.57475 7.00373 8.57475ZM8.34023 18.339H5.66723V9.75H8.34023V18.339ZM19.6697 3H4.32923C3.59498 3 3.00098 3.5805 3.00098 4.29675V19.7033C3.00098 20.4202 3.59498 21 4.32923 21H19.6675C20.401 21 21.001 20.4202 21.001 19.7033V4.29675C21.001 3.5805 20.401 3 19.6675 3H19.6697Z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-column flex-md-row min-vh-100">
        <div
          className="col-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-center"
          style={{
            transform: window.innerWidth >= 992 ? "scale(0.9)" : "scale(1)",
          }}
        >
          <div className="container d-flex flex-column justify-content-center min-vh-100">
            <div className="row justify-content-center">
              <div
                className="col-12 col-md-8 col-lg-6 d-flex flex-column gap-40"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                <div className="d-flex">
                  <a href="/">
                    <img src={LogoOuibild} alt="logo" />
                  </a>
                </div>
                <div
                  className="d-flex flex-column gap-20"
                  data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-delay="200"
                >
                  <h2 className="title-50 bold">
                    Commencez{" "}
                    <span className="purple">
                      votre aventure avec la tokenisation
                    </span>
                  </h2>
                  <p className="grey title-16 bold-400">
                    Commencez à proposer des opportunités d'investissement
                    innovantes à vos clients. Veuillez répondre à quelques
                    questions pour créer votre profil.
                  </p>
                </div>
                <div
                  className="d-flex flex-column gap-10"
                  data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-delay="300"
                >
                  <label htmlFor="email_phone" className="title-14 bold ">
                    Adresse e-mail
                  </label>
                  <input
                    type="email"
                    id="email_phone"
                    placeholder="Entrez votre adresse e-mail"
                    className="form-control rounded-lg shadow-none input-transparent"
                    style={{ padding: "15px 20px" }}
                    onChange={handleInputChange}
                    disabled={emailLoading}
                  />
                  {errorMessage && (
                    <div className="text-danger">{errorMessage}</div>
                  )}
                  <button
                    className="button-p-purple mt-3 w-100 p-25-40 border-40"
                    disabled={emailLoading}
                    onClick={handleStart}
                  >
                    {emailLoading ? "Chargement..." : "Commencer maintenant"}
                  </button>
                </div>
                <div
                  className="d-flex align-items-center gap-20"
                  data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-delay="400"
                >
                  <hr className="flex-grow-1" />
                  <p
                    className="mb-0 title-16 bold-400 grey"
                    style={{ marginInline: "20px" }}
                  >
                    Ou commencez avec
                  </p>
                  <hr className="flex-grow-1" />
                </div>
                <button
                  onClick={connectWithGoogle}
                  className="button-pwhite purple bold w-100 border-r-40 d-flex align-items-center justify-content-cente m-0r"
                  disabled={emailLoading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    className="mx-2"
                  >
                    <path
                      d="M1.56364 6.00914C3.20909 2.74092 6.59084 0.5 10.5 0.5C13.1954 0.5 15.459 1.49095 17.1909 3.10455L14.3227 5.97274C13.2864 4.98185 11.9681 4.47727 10.5 4.47727C7.89542 4.47727 5.69084 6.23637 4.90455 8.6C4.7045 9.2 4.59086 9.8409 4.59086 10.5C4.59086 11.1591 4.7045 11.8 4.90455 12.4C5.69084 14.7636 7.89542 16.5227 10.5 16.5227C11.8454 16.5227 12.9909 16.1682 13.8864 15.5682C14.9454 14.8591 15.65 13.8 15.8818 12.55H10.5V8.6818H19.9181C20.0364 9.3363 20.1 10.0182 20.1 10.7273C20.1 13.7727 19.0091 16.3363 17.1181 18.0773C15.4636 19.6046 13.2 20.5 10.5 20.5C6.59084 20.5 3.20909 18.2591 1.56364 14.9909C0.88638 13.6409 0.5 12.1136 0.5 10.5C0.5 8.8864 0.88638 7.35911 1.56364 6.00914Z"
                      fill="currentColor"
                    />
                  </svg>
                  S'inscrire avec Google
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-6 d-none d-md-flex justify-content-center align-items-center bg-cover"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="600"
        ></div>
      </div>
    </div>
  );
};
