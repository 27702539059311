// Assuming the existence of "../contexts/Web3AuthContext" with proper TypeScript typing
import { useEffect, useRef, useState } from "react"
import MetaMaskOnboarding from "@metamask/onboarding"
import {
  SignInOutput,
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signUp,
  signOut,
} from "aws-amplify/auth"
declare var window: any

interface UseMetamaskSignInReturn {
  signInWithMetamask: () => Promise<void>
  callTest: () => void
  error: Error | null
  signOut: () => Promise<void>
}

export const getRandomString = (bytes: any) => {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join("")
}
const intToHex = (nr: any) => {
  return nr.toString(16).padStart(2, "0")
}

const useMetamaskSignIn = (): UseMetamaskSignInReturn => {
  const [error] = useState<Error | null>(null)
  const onboarding = useRef<MetaMaskOnboarding | null>(null)

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding()
    }
  }, [])

  async function handleAmplifySignIn(address: string): Promise<any> {
    const passw = getRandomString(30)
    return signIn({
      username: address,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
        "custom:authMethod": "metamask",
      },
    })
      .then(async (res) => {
        console.log("SUCCESSFULLY SIGNED IN")
        return res
      })
      .catch(async (err: Error) => {
        console.log(err)
        if (err.message.includes("User does not exist")) {
          const params = {
            username: address,
            password: passw,
            options: {
              userAttributes: {
                "custom:authMethod": "metamask",
              },
            },
          }
          await signUp(params)
            .then((res) => console.log("ok cool", res))
            .catch((err) => console.log(err))
          return handleAmplifySignIn(address)
        } else {
          throw err
        }
      })
  }

  const callTest = async () => {
    const auth = await fetchAuthSession()
    fetch("https://api-metalskin.xyz/api/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.tokens?.accessToken.toString(),
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error("Network response was not ok.")
      })
      .then((data) => {
        console.log("data", data) // Handle the data from the response
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error)
      })
  }

  const onSignIn = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      const accounts: any = await (window.ethereum as any).request({
        method: "eth_requestAccounts",
      })
      if (accounts.length > 0) {
        const address = accounts[0]
        const cognitoUser: SignInOutput = await handleAmplifySignIn(address)
        console.log(cognitoUser)
        const messageToSign =
          cognitoUser.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
            ? cognitoUser.nextStep.additionalInfo!.message
            : "not ok"
        const signature: any = await (window.ethereum as any).request({
          method: "personal_sign",
          params: [address, messageToSign],
        })
        await confirmSignIn({ challengeResponse: signature })
      }
    } else {
      onboarding.current!.startOnboarding()
    }
  }

  return {
    signInWithMetamask: onSignIn,
    callTest,
    error,
    signOut,
  }
}

export default useMetamaskSignIn
