import { Footer } from "../../components/Footer/footer";
import BgImg from "../../assets/background/backgroundHero.png";
import { Navbar } from "../../components/Navbar/navbar";
import logoCardTitle from "../../assets/logo/logoCard.png";
import kyro from "../../assets/logo/kyro.png";
import bgCardEco from "../../assets/background/bgCardeco2.png";
import { Nav } from "../../components/Navbar/nav";

export const Ecosysteme = () => {
  return (
    <div>
      <Nav />
      <section
        className="section-120 d-flex align-items-center p-120-eco"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${BgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="mb-r-20 col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start gap-20 mb-lg-0 mx-auto order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
            >
              <div
                className="card-title d-flex align-items-center"
                style={{ gap: "5px" }}
              >
                <img src={logoCardTitle} alt="card-title card-titleBis" />
                <p className="mb-0 bold-600">
                  Des solutions techniques complémentaires
                </p>
              </div>

              <h2 className="tile-hero">L’écosystème Ouibild</h2>
              <div
                className="d-flex flex-column flex-md-row align-items-md-center align-items-start"
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={400}
              ></div>
            </div>
          </div>
          <a
            className="w-100"
            href="http://bank.ouibild.io/"
            target="_blank"
            rel="noreferrer"
          >
          <div
            className="row d-flex align-items-center my-4 border-r-20 card-ecosysteme"
            style={{
              backgroundImage: `url(${bgCardEco})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={500}
          >
            <div className="col-12 col-md-7 d-flex flex-column p-4 text-white">
              <span className="title-18 bold mb-3">Groupe</span>
              <h3 className="title-48 bold mb-3 linear-purple">
                Écosystème financier <br />
                powered by Ouibildbank
              </h3>
              <p className="text-16 bold-400 mb-3">
                Votre compte bancaire qui accepte vos cryptos et qui vous assure
                un compte rémunéré à 7% !
              </p>
              <button className="btn-border-white mt-auto align-self-start">
                Visiter Ouibild Bank
              </button>
            </div>
          </div>
          </a>
          <a
            className="w-100"
            href="http://kyro.ouibild.io/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="row d-flex align-items-center my-4 border-r-20 card-ecosysteme"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={600}>

              <div className="col-12 col-md-7 d-flex flex-column justify-content-around p-4 text-white">
                <span className="title-18 bold mb-3">Groupe</span>
                <h3 className="title-48 bold mb-3">
                  Écosystème financier <br />{" "}
                  <span className="red">powered by Kyro</span>
                </h3>
                <p className="text-16 bold-400 mb-3">
                  Votre société de gestion d’investissement crypto sur-mesure
                </p>
                <button className="button-red mt-auto align-self-start">
                  Visiter Kyro
                </button>
              </div>
              <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                <img src={kyro} alt="card" />
              </div>
            </div>
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
};
