import { useState } from "react";
import AccordImg from "../../assets/logo/accordion.png";

const AccordionMenu = () => {
  const [openId, setOpenId] = useState<number | null>(null);

  const toggleOpen = (id: number) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <div className="accordion">
      {[
        {
          id: 1,
          question: "Votre produit tokénisé  ",
          response:
            "Diversifiez les portefeuilles de vos clients avec des produits d'investissement innovants et variés, permettant de maximiser les rendements tout en minimisant les risques.",
        },
        {
          id: 2,
          question: "Votre smart contract  ",
          response:
            "Les smart contacts sont établis aux niveaux techniques et définissent les termes qui vous lieront à vos investisseurs : rendement, dividende, durée... Tous les termes du contrats et leur exécution sont par la suite automatisée. Vous n’avez rien à faire si ce n’est répondre à vos engagements ;) ",
        },
        {
          id: 3,
          question: "Accédez à des Opportunités Exclusives",
          response:
            "Proposez à vos clients des investissements uniques et à haute valeur ajoutée, accessibles grâce à la tokenisation.",
        },
      ].map((item) => (
        <div
          className="mb-3"
          key={item.id}
          onClick={() => toggleOpen(item.id)}
          style={{ cursor: "pointer" }}
        >
          <div className="card-accordion d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={AccordImg}
                  alt="accordion"
                  className="accordion-img"
                />
                <p className="title-16 bold-600 ms-2 mb-0 purple">
                  {item.question}
                </p>
              </div>
              {openId === item.id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.9992 7.8284L5.63516 13.1924L4.22097 11.7782L11.9992 4L19.7773 11.7782L18.3631 13.1924L12.9992 7.8284L12.9992 20L10.9992 20L10.9992 7.8284Z"
                    fill="#3500A5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13.0008 16.1716L18.3648 10.8076L19.779 12.2218L12.0008 20L4.22266 12.2218L5.63688 10.8076L11.0008 16.1716L11.0008 4L13.0008 4L13.0008 16.1716Z"
                    fill="#3500A5"
                  />
                </svg>
              )}
            </div>
            <div className={`response ${openId === item.id ? "show" : "hide"}`}>
              <div className="mt-3">{item.response}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionMenu;
