import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

type DataContextType = {
  emailData: string;
  phoneNumberData: string;
  setEmailData: Dispatch<SetStateAction<string>>;
  setPhoneNumberData: Dispatch<SetStateAction<string>>;
};

const DataFormContext = createContext<DataContextType>({
  emailData: "",
  setEmailData: () => {},
  phoneNumberData: "",
  setPhoneNumberData: () => {},
});

export const useDataForm = () => useContext(DataFormContext);

type DataFormProviderProps = {
  children: ReactNode;
};

export const DataFormProvider: React.FC<DataFormProviderProps> = ({
  children,
}) => {
  const [emailData, setEmailData] = useState<string>("");
  const [phoneNumberData, setPhoneNumberData] = useState<string>("");

  return (
    <DataFormContext.Provider
      value={{ emailData, setEmailData, phoneNumberData, setPhoneNumberData }}
    >
      {children}
    </DataFormContext.Provider>
  );
};
