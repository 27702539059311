import { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useWeb3Auth } from "../../authent/contexts/Web3AuthContext";
import { toast } from "react-toastify";
import { Loading } from "../Loading/Loading";

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { userMe } = useWeb3Auth(); // Access user data
  const [loading, setLoading] = useState(true); // Add loading state
  const [shouldNavigate, setShouldNavigate] = useState<boolean | null>(null); // Navigation state

  useEffect(() => {
    const checkUser = async () => {
      if (userMe === undefined) {
        setLoading(true);
      }
      if (!userMe) {
        setLoading(true);
        return;
      }
      if (!userMe) {
        toast.error(
          "Vous devez remplir le formulaire pour accéder à cette page."
        );
        setShouldNavigate(false);
      } else if (userMe.form) {
        setShouldNavigate(true);
      } else {
        // If the user hasn't completed the form
        toast.error(
          "Vous devez remplir le formulaire pour accéder à cette page."
        );
        setShouldNavigate(false);
      }

      setLoading(false);
    };

    checkUser();
  }, [userMe]);

  if (loading) {
    return <Loading />; // Display a loading state
  }

  if (shouldNavigate === false) {
    return <Navigate to="/" replace />; // Redirect to home if the user cannot access the page
  }

  return <>{children}</>; // Render protected content if user is allowed
};

export default ProtectedRoute;
