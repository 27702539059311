import { getJwt } from "../authent/utils/back";

export class ApiService {
  private baseUrl: string;
  private jwt: string | null = null;

  constructor() {
    this.baseUrl = " https://api.ouibild.io/api";
  }

  private async getAuthHeader(): Promise<string> {
    if (!this.jwt) {
      await this.initializeJwt();
    }
    if (!this.jwt) {
      throw new Error("Failed to obtain JWT.");
    }
    return this.jwt;
  }

  private async initializeJwt(): Promise<void> {
    this.jwt = await getJwt();
  }

  public async get<T>(endpoint: string): Promise<T | string | null> {
    try {
      const token = await this.getAuthHeader();
      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      // Check the content type of the response
      const contentType = response.headers.get("Content-Type");

      if (contentType && contentType.includes("application/json")) {
        const data: T = await response.json();
        return data;
      } else if (contentType && contentType.includes("image/svg+xml")) {
        const svgData = await response.text(); // Handle SVG as text
        return svgData;
      } else {
        throw new Error(`Unsupported content type: ${contentType}`);
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      return null;
    }
  }

  public async post<T, U>(endpoint: string, body: U): Promise<T | null> {
    try {
      const token = await this.getAuthHeader();
      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const data: T = await response.json();
      return data;
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      return null;
    }
  }

  public async put<T, U>(endpoint: string, body: U): Promise<T | null> {
    try {
      const token = await this.getAuthHeader();
      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const data: T = await response.json();
      return data;
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      return null;
    }
  }
}
