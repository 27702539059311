import { CHAIN_NAMESPACES } from "@web3auth/base"

export const clientId = "BM4Qmp5Tnt8VVLPSRv92gFfsXeHAsDhFOFBP0fuZmssT-tMuAVV3k9ZW-n2doKbJZnlAlXqW-09tnAZGjH_4Ig0"

export const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155, // This remains the same as it's a standard Ethereum-compatible chain namespace
    chainId: "0x89", // The chain ID for Polygon Mainnet is 137 in decimal, which is 0x89 in hexadecimal
    rpcTarget: "https://polygon-rpc.com/", // One of the public RPC URLs for Polygon
    displayName: "Polygon Mainnet",
    blockExplorerUrl: "https://polygonscan.com/", // The block explorer URL for Polygon
    ticker: "MATIC", // The native token for Polygon
    tickerName: "Polygon (MATIC)",
    logo: "https://cryptologos.cc/logos/polygon-matic-logo.png", // Change this to a logo URL for Polygon if available
}
