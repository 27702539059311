import { useEffect } from "react";
import useAbstractConnect from "../../authent/hooks/useAbstractConnect";

const Signout = () => {
  const { fullLogout } = useAbstractConnect();

  useEffect(() => {
    fullLogout();
  }, []);

  return <div>Signout</div>;
};

export default Signout;
