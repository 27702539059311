import { useState } from "react";
import { SignUpFormStep1Props } from "../../interface/SignUpFormStepPros";
import { NavLink, useNavigate } from "react-router-dom";

export const SignUpFormStep7: React.FC<SignUpFormStep1Props> = ({
  setStep,
  setInformationsUser,
  informationsUser,
  handleNextStep,
  handlePrevStep,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="container d-flex align-items-center justify-content-center min-vh-100"
      data-aos="fade-up"
      data-aos-duration={700}
      data-aos-delay={300}
    >
      <div className="row justify-content-center w-100">
        <div
          className="col-12 col-md-10 col-lg-6 d-flex flex-column align-items-center text-center gap-20"
          style={{
            transform: window.innerWidth >= 992 ? "scale(0.9)" : "scale(1)",
          }}
          data-aos="fade-up"
          data-aos-duration={700}
          data-aos-delay={400}
        >
          <h2 className="title-40 bold">Bienvenue chez Ouibild !</h2>
          <p className="title-20 bold black-05">
            Félicitations, votre inscription est maintenant complète ! Nous
            sommes ravis de vous accueillir sur Ouibild. Vous pouvez désormais
            commencer à proposer des opportunités d'investissement innovantes à
            vos clients.
          </p>

          <a
            className="button-pblack mt-1 border-r-10 p-20-30"
            href="/mint"
          >
            Suivant
          </a>
        </div>
      </div>
    </div>
  );
};
