import { useState } from "react";
import axios from "axios";
import { SirenResponse } from "../types/siret";
import { BEARER_TOKEN_GOUV } from "../config/apiKeyGouv";

// Type pour stocker l'établissement
interface Establishment {
  siren: string;
  siret?: string;
  name: string;
}

const useSiretData = () => {
  const [etablissementData, setEtablissementData] = useState<Establishment | null>(null);

  // Recherche par SIRET (14 chiffres)
  const fetchEtablissementDataBySiret = async (siret: string) => {
    if (!siret || siret.length !== 14) {
      setEtablissementData(null);
      return;
    }

    try {
      const response = await axios.get<SirenResponse>(
        `https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`,
        {
          headers: {
            Authorization: `${BEARER_TOKEN_GOUV}`,
          },
        }
      );

      const { etablissement } = response.data;
      console.log(response.data);

      if (etablissement) {
        const establishment: Establishment = {
          siren: etablissement.siren,
          siret: etablissement.siret,
          name: etablissement.uniteLegale?.denominationUniteLegale || etablissement.uniteLegale?.nomUniteLegale || "Nom non disponible",
        };

        setEtablissementData(establishment);
      } else {
        setEtablissementData(null);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
      setEtablissementData(null);
    }
  };

  // Recherche par SIREN (9 chiffres)
  const fetchEtablissementDataBySiren = async (siren: string) => {
    if (!siren || siren.length !== 9) {
      setEtablissementData(null);
      return;
    }

    try {
      const response = await axios.get<any>(
        `https://api.insee.fr/entreprises/sirene/V3.11/siren/${siren}`,
        {
          headers: {
            Authorization: `${BEARER_TOKEN_GOUV}`,
          },
        }
      );
    
      const { uniteLegale } = response.data;
    
      if (uniteLegale) {
        const establishment: Establishment = {
          siren: uniteLegale.siren,
          name: uniteLegale.periodesUniteLegale[0]?.denominationUniteLegale || "Nom non disponible", 
        };
    
        setEtablissementData(establishment);
      } else {
        setEtablissementData(null);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
      setEtablissementData(null);
    }
  };

  return {
    etablissementData,
    fetchEtablissementDataBySiret,
    fetchEtablissementDataBySiren,
    setEtablissementData
  };
};

export default useSiretData;
