import { Otp } from "../../types/user";
import { ApiService } from "../ApiService";

const apiService = new ApiService();



export const sendOtp = async (
    otpData: Partial<Otp>
  ): Promise<Otp | null> => {
    return await apiService.post<Otp, Partial<Otp>>("/otp/send", otpData);
  };

export const verifyOtp = async (
    otpData: Partial<Otp>
  ): Promise<Otp | null> => {
    return await apiService.post<Otp, Partial<Otp>>("/otp/verify", otpData);
  };