import { ReactNode } from "react";
import OuibildLogo from "../../assets/logo/logoOuibild.svg";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="d-flex flex-column w-100 align-items-center p-3">
      <div className="col-12 col-md-8">
        <div className="d-flex flex-column min-vh-100 justify-content-start align-items-center w-100">
          <div
            className="w-100 d-flex justify-content-start"
            style={{ paddingTop: "40px" }}
          >
            <a href="/">
              <img
                src={OuibildLogo}
                alt="Metalskin Logo"
                style={{ height: "24px" }}
              />
            </a>
          </div>
          <div className="d-flex flex-grow-1 justify-content-center align-items-center w-100">
            <div className="col-12">
              <div className="content fade-in">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
