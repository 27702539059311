// Assuming the existence of "../contexts/Web3AuthContext" with proper TypeScript typing
import { useState } from "react"
import { signInWithRedirect } from "aws-amplify/auth"

interface UseSocialSignReturn {
  signInWithGoogle: () => Promise<boolean | undefined>
  error: Error | null
} 

const useSocialSign = (): UseSocialSignReturn => {
  const [error] = useState<Error | null>(null)

  const signInWithGoogle = async () => {
    try {
      await signInWithRedirect({
        provider: "Google",
      })
      return true
    } catch (error) {
      console.log("error", error)
      console.log("Already signed in")
    }
  }

  return {
    signInWithGoogle,
    error,
  }
}

export default useSocialSign
