export const Loading = () => {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100vh' }} 
        >
            <div className="spinner-border" style={{color: "#3500A5"}} role="status">
            </div>
        </div>
    );
};
