import { useState } from "react"
import { SignInOutput, SignUpOutput, signIn, signUp } from "aws-amplify/auth"
import { getRandomString } from "./useMetamaskSign"

interface UseEmailSignInReturn {
    signInWithEmail: (email: string) => Promise<SignInOutput | SignUpOutput | undefined>
    error: Error | null
}

const useEmailSignIn = (): UseEmailSignInReturn => {
    const [error] = useState<Error | null>(null)
    function generatePassword(length = 8) {
        const numbers = "0123456789"
        const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?"
        const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const lowerCase = "abcdefghijklmnopqrstuvwxyz"

        // Ensure password meets all requirements
        const getRandomChar = (str: any) => str[Math.floor(Math.random() * str.length)]

        // Start the password with one character from each required set
        let password = ""
        password += getRandomChar(numbers)
        password += getRandomChar(specialChars)
        password += getRandomChar(upperCase)
        password += getRandomChar(lowerCase)

        // Fill the rest of the password to meet the desired length
        const allChars = numbers + specialChars + upperCase + lowerCase
        while (password.length < length) {
            password += getRandomChar(allChars)
        }

        // Shuffle the resulting password to ensure randomness
        password = password
            .split("")
            .sort(() => Math.random() - 0.5)
            .join("")

        return password
    }
    const signInWithEmail = async (email: string) => {
        const passw = generatePassword()
        console.log(email)
        try {
            const res = await signIn({
                username: email,
                options: {
                    authFlowType: "CUSTOM_WITHOUT_SRP",
                    userAttributes: {
                        "custom:authMethod": "email",
                        email: email,
                    },
                },
            })

            console.log(res)
            const result = res as any
            console.log(result.nextStep.additionalInfo)

            if (
                result.nextStep.additionalInfo.message &&
                result.nextStep.additionalInfo.message?.includes("User not found")
            ) {
                console.log("NOT SIGNED IN")

                const params = {
                    username: email,
                    password: passw,
                    options: {
                        userAttributes: {
                            email: email,
                            "custom:authMethod": "email",
                        },
                    },
                }

                try {
                    const signUpRes = await signUp(params)
                    console.log("ok cool", signUpRes)
                    const res = await signIn({
                        username: email,
                        options: {
                            authFlowType: "CUSTOM_WITHOUT_SRP",
                            userAttributes: {
                                "custom:authMethod": "email",
                                email: email,
                            },
                        },
                    })
                    if (
                        !result.nextStep.additionalInfo.message &&
                        !result.nextStep.additionalInfo.message?.includes("User not found")
                    ) {
                        console.log(res)
                        console.log("Registered and connected")
                    }
                    return signUpRes
                } catch (signupErr: any) {
                    console.log(signupErr)
                    throw new Error(signupErr)
                }
            } else {
                console.log("SUCCESSFULLY SIGNED IN")
            }
            return res
        } catch (err: any) {
            console.log(err)
        }
    }

    return {
        signInWithEmail,
        error,
    }
}

export default useEmailSignIn
