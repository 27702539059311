import React, { useEffect, useState } from "react";
import LoadingIcon from "../../assets/logo/LogoOuibildPurple.png";
import useEmailSignIn from "../../authent/hooks/useEmailSign";
import useSmsSignIn from "../../authent/hooks/usePhoneSign";
import { useDataForm } from "../../hook/DataFormProvider";
import useWeb3Actions from "../../authent/hooks/useWeb3Actions";
import Layout from "../Layout/Layout";
const EmailVerification: React.FC = ({}) => {
  const [codeValidation, setCodeValidation] = useState("");
  const [verificationError, setVerificationError] = useState(false);
  const { verifyOtp } = useWeb3Actions();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { emailData, phoneNumberData } = useDataForm();
  const { signInWithEmail } = useEmailSignIn();
  const { signInWithPhoneNumber } = useSmsSignIn();
  const [timeLeft, setTimeLeft] = useState(60);
  const [timeExpired, setTimeExpired] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = e.target.value;
    setCodeValidation(newCode);
    if (newCode.length === 6) {
      handleSubmit(newCode);
    }
  };

  useEffect(() => {
    if (!loading && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setTimeExpired(true);
    }
  }, [loading, timeLeft]);

  const handleResendCode = () => {
    setTimeLeft(60);
    setTimeExpired(false);
  };

  const handleResendEmailCode = () => {
    signInWithEmail(emailData);
    handleResendCode();
  };

  const handleResendPhoneNumberCode = () => {
    signInWithPhoneNumber(phoneNumberData);
    handleResendCode();
  };

  const handleSubmit = async (code: string) => {
    setSubmitted(true);
    setLoading(true);
    setVerificationError(false);
    console.log(code.length);
    if (code.length < 6) {
      setVerificationError(true);
      console.log("Please fill out all required fields.");
      return;
    }

    try {
      console.log("codeValidation", code);
      const res = await verifyOtp(code);
      console.log(res);
    } catch (error) {
      console.error(error);
      setVerificationError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="text-start mb-3"></div>
      <div>
        <>
          <h2 className="blue-gradient title-35 semi-bold ">
            Vérification de l'e-mail
          </h2>
          <p className="text-grey">
            Veuillez saisir le code de vérification à 6 chiffres qui a été
            envoyé à votre e-mail <strong>{emailData}</strong>
            <br></br>
          </p>
        </>
      </div>
      <div className="d-flex  gap-2 mb-4 ">
        <div className="position-relative">
          <input
            className={`form-control shadow-none title-35 text-center p-3 ${
              submitted && verificationError ? "border-danger" : ""
            }`}
            type="text"
            maxLength={6}
            value={codeValidation}
            onChange={handleChange}
            disabled={loading}
            style={{ opacity: submitted && loading ? 0.5 : 1 }}
          />
        </div>
      </div>
      <strong className="text-red">
        Si vous ne recevez pas d’e-mail de confirmation, veuillez verifier dans
        vos courriers indésirables
      </strong>
      {submitted && verificationError && (
        <>
          <p className="text-danger mt-4" role="alert">
            Vérification échouée. Veuillez réessayer.
          </p>
          <button>Renvoyer le code</button>
        </>
      )}
      {timeExpired ? (
        emailData ? (
          <button onClick={handleResendEmailCode} className="button-blue mt-2">
            Renvoyer le code
          </button>
        ) : (
          <button
            onClick={handleResendPhoneNumberCode}
            className="button-blue mt-2"
          >
            Renvoyer le code
          </button>
        )
      ) : (
        <p className="text-grey my-3">
          Renvoyer le code dans <strong>{timeLeft}</strong> secondes
        </p>
      )}
    </Layout>
  );
};

export default EmailVerification;
