// Web3AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react"
import { Web3AuthNoModal } from "@web3auth/no-modal"
import { WALLET_ADAPTERS, WEB3AUTH_NETWORK } from "@web3auth/base"
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider"
import { chainConfig, clientId } from "../constants/web3auth"
import { OpenloginAdapter } from "@web3auth/openlogin-adapter"
import { User } from "../../types/user"
import { AuthSession, fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth"
import { updateUserWithWeb3AndCognito } from "../utils/user"
import { getUser } from "../utils/back"

interface IWeb3AuthContext {
    web3auth: Web3AuthNoModal | null
    loggedIn: boolean | null
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>
    userMe: User | null
    setUserMe: React.Dispatch<React.SetStateAction<User | null>>
    setAuthSession: React.Dispatch<React.SetStateAction<AuthSession | null>>
    refreshUser: () => Promise<void>
    error: string | null
}

const Web3AuthContext = createContext<IWeb3AuthContext | null>(null)

interface Web3AuthProviderProps {
    children: ReactNode
}

export const useWeb3Auth = (): IWeb3AuthContext => {
    const context = useContext(Web3AuthContext)
    if (!context) {
        throw new Error("useWeb3Auth must be used within a Web3AuthProvider")
    }
    return context
}

export const Web3AuthProvider: React.FC<Web3AuthProviderProps> = ({ children }) => {
    const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null)
    const [loggedIn, setLoggedIn] = useState<boolean | null>(null)
    const [userMe, setUserMe] = useState<User | null>(null)
    const [authSession, setAuthSession] = useState<AuthSession | null>(null)
    const [error, setError] = useState<string | null>(null) // Error state

    const init = async () => {
        try {
            const privateKeyProvider = new EthereumPrivateKeyProvider({
                config: { chainConfig },
            })

            const web3auth = new Web3AuthNoModal({
                clientId,
                web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
                privateKeyProvider,
            })

            const openloginAdapter = new OpenloginAdapter({
                loginSettings: {
                    mfaLevel: "none", // 2FA
                },
                adapterSettings: {
                    uxMode: "redirect",
                    loginConfig: {
                        jwt: {
                            name: "OuiBuildVerification",
                            verifier: "cognito-verifier2",
                            typeOfLogin: "jwt",
                            clientId: "28u3qf8lmrei7a1pm875di87vf",
                        },
                    },
                },
                privateKeyProvider: privateKeyProvider as any,
            })

            web3auth.configureAdapter(openloginAdapter)

            await web3auth.init()
            setWeb3auth(web3auth)

            const auth = await fetchAuthSession()
            const userCognito = await fetchUserAttributes()

            if (userCognito && typeof userCognito.identities === "string") {
                const identities = JSON.parse(userCognito.identities)

                const providerName = identities && identities.length > 0 ? identities[0].providerName : null

                if (providerName === "Google" && auth?.tokens?.idToken && !web3auth.connected) {
                    try {
                        const idToken = auth.tokens.idToken.toString()
                        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
                            loginProvider: "jwt",
                            web3AuthNetwork: "sapphire_devnet",
                            extraLoginOptions: {
                                id_token: idToken,
                                domain: "https://ouibuild.auth.eu-central-1.amazoncognito.com",
                                verifierIdField: "sub",
                            },
                        })
                    } catch (error) {
                        setError("Failed to connect with Google")
                        throw error // Propagate the error upwards
                    }
                }
            }

            if (authSession?.tokens?.idToken && !web3auth.connected) {
                try {
                    const idToken = authSession.tokens.idToken.toString()
                    await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
                        loginProvider: "jwt",
                        web3AuthNetwork: "sapphire_devnet",
                        extraLoginOptions: {
                            id_token: idToken,
                            domain: "https://ouibuild.auth.eu-central-1.amazoncognito.com",
                            verifierIdField: "sub",
                        },
                    })
                    console.log("Connection successful")
                } catch (error) {
                    setError("Failed to connect during session initialization")
                }
            }

            if (auth.userSub && web3auth.connected) {
                try {
                    const user = await getUser()
                    const cognitoDetails = await fetchUserAttributes()
                    const userComplete = updateUserWithWeb3AndCognito(user, web3auth, cognitoDetails)
                    setUserMe(userComplete)
                    setLoggedIn(true)
                } catch (error) {
                    setUserMe({} as User)
                    setLoggedIn(false)
                }
            }
        } catch (error) {
            setError("Failed to fetch or update user")
            setUserMe({} as User)
            setLoggedIn(false)
        }
    }

    const refreshUser = async () => {
        try {
            const user = await getUser()
            const cognitoDetails = await fetchUserAttributes()
            const userComplete = web3auth && updateUserWithWeb3AndCognito(user, web3auth, cognitoDetails)
            console.log("User refreshed:", userComplete)
            setUserMe(userComplete)
        } catch (error) {
            setError("Failed to refresh user")
            console.error("Failed to refresh user:", error)
            throw error // Propagate the error upwards
        }
    }

    useEffect(() => {
        init()
    }, [authSession])

    return (
        <Web3AuthContext.Provider
            value={{
                web3auth,
                loggedIn,
                setLoggedIn,
                userMe,
                setUserMe,
                setAuthSession,
                refreshUser,
                error,
            }}
        >
            {children}
        </Web3AuthContext.Provider>
    )
}
