import { Footer } from "../components/Footer/footer"
import { Navbar } from "../components/Navbar/navbar"
import BigBgPurple from "../assets/background/bigBgPurple.png";

export const Legal = () => {
    return (
        <div>
      <Navbar />
      {/** Hero */}
      <section
        className="d-flex align-items-center p-120-hero"
        style={{
          minHeight: "100vh",
          
        }}
      >
        <div className="container d-flex flex-column justify-content-between pt-300" style={{paddingBottom: "100px"}}>
        <div className="content mt-4 black">
            <div >
              <h3 className="title-30 bold text-start"> 1. IDENTIFICATION</h3>
              <p className="title-18 bold-500 ">
              OUIBILD SAS
                <br />
                919 684 357 R.C.S. Orleans
                <br />
                3 AVENUE JEAN ZAY, 45000 ORLEANS
                <br />
                Email : <strong>contact@ouibild.io</strong>
              </p>
            </div>
            <div className="mt-4">
              <h3 className="title-30 bold text-start">
                {" "}
                2. ADRESSE DE CONTACT
              </h3>
              <p className="title-18 bold-500 ">
              Le client peut contacter OUIBILD dans le cadre des présentes Mentions Légales et d’information aux adresses listées ci-dessus ou sur le site Internet.
              </p>
            </div>
            <div className="mt-4">
              <h3 className="title-30 bold text-start">
                {" "}
                3. DONNÉES PERSONNELLES
              </h3>
              <p className="title-18 bold-500 ">
              OUIBILD est amenée à collecter des données relatives à ses clients. Les informations collectées seront conservées pendant 5 ans par OUIBILD. Elles pourront être utilisées à des fins commerciales par OUIBILD qui se réserve le droit de les transmettre à des tiers. Il est possible de s’opposer à cette communication en informant OUIBILD à l’adresse de contact. <br />{" "}
                <br />
                En application de la Loi dite «Informatique et Liberté» (Loi nº78-17 du 6 janvier 1978), vous avez la possibilité d’accéder aux données personnelles recueillies par OUIBILD, d’en demander la modification, la rectification ou la suppression en contactant OUIBILD à l’adresse de contact.
              </p>
            </div>
            <div className="mt-4">
              <h3 className="title-30 bold text-start">
                {" "}
                4. INFORMATION RELATIVE À LA NAVIGATION SUR INTERNET
              </h3>
              <p className="title-18 bold-500 ">
              Il revient à chaque utilisateur d’Internet de prendre des mesures afin d’assurer la confidentialité de ces correspondances sur le réseau. Il lui revient également de s’assurer qu’il a mis en place une protection adéquate et régulièrement mise à jour contre les virus et autres atteintes pouvant endommager les données, les logiciels et l’ordinateur lui-même, lorsqu’il se connecte sur Internet.
              </p>
            </div>
            <div className="mt-4">
              <h3 className="title-30 bold text-start">
                {" "}
                5. RESPECT DES DROITS ET PROPRIÉTÉ INTELLECTUELLE
              </h3>
              <p className="title-18 bold-500 ">
              Le client s’engage à ne pas porter atteinte aux droits de propriété intellectuelle attachés aux produits vendus, et notamment aux droits d’auteur existants sur les progiciels proposés par OUIBILD et livrés sur support ou par téléchargement. A cet égard, il s’engage notamment à une exploitation personnelle de ces progiciels et logiciels, sans effectuer de copie et en conformité avec la licence jointe au progiciel ou au logiciel. <br /> <br />
              Le client garantit OUIBILD contre toute action dont elle pourrait faire l’objet de fait de la violation par lui-même, ses contractants ou des tiers autorisés par ces derniers, des droits de propriété intellectuelle attachés aux progiciels et logiciels, et s’engage à indemniser OUIBILD de tout préjudice subi. Il s’engage par ailleurs à ne pas porter atteinte aux droits de la société OUIBILD sur ses marques.<br /> <br />
              Les images, textes, graphismes, et autres éléments apparaissant dans les pages du site OUIBILD sont la propriété de la société OUIBILD ou utilisés sur le site avec l’autorisation spéciale des titulaires de leurs droits. Ces éléments ne sauraient être repris, copiés ou imités, en partie ou en totalité, sur tout support, par quelque moyen et dans quelque but que ce soit. <br /> <br />
              Toute personne souhaitant installer un lien hypertexte en direction du site de la société OUIBILD doit obtenir, au préalable, l’autorisation écrite de celle-ci. Pour ce faire, elle doit contacter OUIBILD à l’adresse de contact. OUIBILD n’engage aucunement sa responsabilité à raison de tout lien hypertexte apparaissant sur son site et menant à d’autres sites Internet, notamment quant aux contenus et aux pratiques de ces sites.

              </p>
            </div>
          </div>
        </div>
      </section>
      {/** Hero */}

      {/** SECTION REVENU */}
     
      <Footer />
    </div>
    )
}