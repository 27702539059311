import React, { useState, ChangeEvent } from "react";
import { SignUpFormStep1Props } from "../../interface/SignUpFormStepPros";
import { FormUser } from "../../types/user";
import useSiretData from "../../hook/useGouvApi";
import LogoOuibild from "../../assets/logo/logoFullPurple.svg";

enum ExperienceYears {
  ONE_TO_FIVE = "0-5 ans",
  FIVE_TO_TEN = "5-10 ans",
  TEN_TO_FIFTEEN = "10-15 ans",
  FIFTEEN_TO_TWENTY = "15-20 ans",
}

export const SignUpFormStep3: React.FC<SignUpFormStep1Props> = ({
  setInformationsUser,
  informationsUser,
  handleNextStep,
  handlePrevStep,
}) => {
  const [selectedExperience, setSelectedExperience] = useState<
    keyof typeof ExperienceYears
  >(
    (Object.keys(ExperienceYears).find(
      (key) =>
        ExperienceYears[key as keyof typeof ExperienceYears] ===
        informationsUser.experienceYears
    ) as keyof typeof ExperienceYears) || "ONE_TO_FIVE"
  );

  const [inputValue, setInputValue] = useState("");
  const {
    etablissementData,
    fetchEtablissementDataBySiren,
    fetchEtablissementDataBySiret,
    setEtablissementData,
  } = useSiretData();
  const [isSiretConfirmed, setIsSiretConfirmed] = useState(false);
  const [isSirenUsed, setIsSirenUsed] = useState(false);

  const handleSelect = (experience: keyof typeof ExperienceYears) => {
    setSelectedExperience(experience);
    setInformationsUser((prevInfo: FormUser) => ({
      ...prevInfo,
      experienceYears: experience,
    }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;

    if (id === "position") {
      setInformationsUser((prevInfo: any) => ({
        ...prevInfo,
        position: value,
      }));
      return;
    }

    if (value.length < 9) {
      setInformationsUser((prevInfo: any) => ({
        ...prevInfo,
        // siret: "",
        // siren: "",
        companyName: prevInfo.companyName || "",
      }));
      setEtablissementData(null);
      setIsSiretConfirmed(false);
      setIsSirenUsed(false);
      setInputValue(value);
      return;
    }

    // Si l'input correspond à un SIREN (9 chiffres)
    if (value.length === 9) {
      fetchEtablissementDataBySiren(value);
      setIsSirenUsed(true);

      setInformationsUser((prevInfo: any) => ({
        ...prevInfo,
        // siren: value,
        // siret: "",
        companyName: "",
      }));
    }

    // Si l'input correspond à un SIRET (14 chiffres)
    if (value.length === 14) {
      fetchEtablissementDataBySiret(value);

      setInformationsUser((prevInfo: any) => ({
        ...prevInfo,
        // siret: value,
        // siren: "",
        companyName: "",
      }));
    }

    setInputValue(value);
  };

  const handleNext = () => {
    setInformationsUser((prevInfo: FormUser) => ({
      ...prevInfo,
      experienceYears: selectedExperience,
    }));
    handleNextStep();
  };

  const handlePrev = () => {
    setInformationsUser((prevInfo: FormUser) => ({
      ...prevInfo,
      experienceYears: selectedExperience,
    }));
    handlePrevStep();
  };

  const handleSelectCompany = (company: any) => {
    console.log("Entreprise sélectionnée :", company);

    setInformationsUser((prevInfo: any) => ({
      ...prevInfo,
      // siret: company.siret || prevInfo.siret,
      // siren: company.siren || prevInfo.siren,
      companyName: company.name || "Nom indisponible",
    }));
    setIsSiretConfirmed(true);
  };

  const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setInformationsUser((prevInfo: any) => ({
      ...prevInfo,
      companyName: value,
      // siret: prevInfo.siret,
      // siren: prevInfo.siren,
    }));
  };

  return (
    <div
      className="container d-flex align-items-center justify-content-center min-vh-100 padding-r-t-b20 "
      data-aos="fade-up"
      data-aos-duration={700}
      data-aos-delay={300}
    >
      <div className="row justify-content-center w-100">
        <div className="col-12 col-md-10 col-lg-6 d-flex flex-column gap-40" style={{
      transform: window.innerWidth >= 992 ? "scale(0.9)" : "scale(1)",
    }}>
          <div className="d-flex" data-aos-duration={700} data-aos-delay={300}>
            <a href="/">
              <img src={LogoOuibild} alt="logo" />
            </a>
          </div>
          <div>
            <div className="row text-start mb-2">
              <button
                className="button-transparent d-flex align-items-center"
                onClick={handlePrev}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <g>
                    <path
                      d="M6.52385 9.66658H16.6668V11.3332H6.52385L10.9938 15.8032L9.81533 16.9817L3.3335 10.4999L9.81533 4.01807L10.9938 5.19657L6.52385 9.66658Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>{" "}
                Retour
              </button>
            </div>
            <h2 className="title-40 bold">Votre Expérience CGP</h2>
            <p className="title-20">
              Nous aimerions en savoir plus sur votre parcours professionnel
              pour adapter nos services à vos besoins.
            </p>
          </div>

          <div className="d-flex flex-column gap-30 w-100">
            <div className="col-12 mb-3 form-group">
              <label htmlFor="siret" className="title-14 bold mb-2 d-block">
                SIRET ou SIREN (optionnel)
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  id="siret"
                  placeholder="Entrez votre SIRET ou SIREN"
                  className="form-control rounded-lg shadow-none input-transparent"
                  style={{
                    padding: "15px 20px",
                    paddingRight: isSiretConfirmed ? "40px" : "20px",
                  }} // Ajouter un espace pour le SVG
                  value={inputValue}
                  onChange={handleInputChange}
                />

                {/* SVG à afficher si isSiretConfirmed */}
                {isSiretConfirmed && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <path
                      d="M12.5019 18.9649L23.9919 7.47363L25.7606 9.24113L12.5019 22.4999L4.54688 14.5449L6.31438 12.7774L12.5019 18.9649Z"
                      fill="#50D485"
                    />
                  </svg>
                )}

                {/* Affichage des suggestions ou des messages selon que le SIREN ou SIRET est utilisé */}
                {etablissementData && !isSiretConfirmed && (
                  <div className="address-suggestions-container">
                    {isSirenUsed ? (
                      <div
                        onClick={() => handleSelectCompany(etablissementData)}
                        className="address-suggestion"
                      >
                        {etablissementData.name} - Cliquez pour valider
                      </div>
                    ) : (
                      <div
                        onClick={() => handleSelectCompany(etablissementData)}
                        className="address-suggestion"
                      >
                        {etablissementData.name} - Cliquez pour valider
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="companyName"
                className="title-14 bold mb-2 d-block"
              >
                Nom de l'entreprise
              </label>
              <input
                type="text"
                id="companyName"
                placeholder="Entrez le nom de l’entreprise"
                className="form-control rounded-lg shadow-none input-transparent"
                style={{ padding: "15px 20px" }}
                value={informationsUser.companyName || ""}
                onChange={(e) => handleCompanyNameChange(e)}
              />
            </div>

            <div>
              <label htmlFor="position" className="title-14 bold mb-2 d-block">
                Poste occupé
              </label>
              <input
                type="text"
                id="position"
                placeholder="Entrez le poste occupé"
                className="form-control rounded-lg shadow-none input-transparent"
                style={{ padding: "15px 20px" }}
                value={informationsUser.position}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label
                htmlFor="experienceYears"
                className="title-14 bold mb-2 d-block"
              >
                Année d’Expérience en CGP
              </label>
              <div className="row">
                {Object.keys(ExperienceYears).map((key) => (
                  <div
                    key={key}
                    className={`col-6 col-md-6 col-lg-3 d-flex justify-content-center mb-2`}
                  >
                    <div
                      className={`experience-box ${
                        selectedExperience === key ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleSelect(key as keyof typeof ExperienceYears)
                      }
                    >
                      {ExperienceYears[key as keyof typeof ExperienceYears]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/** 
            <div>
              <label
                htmlFor="certifications"
                className="title-14 bold mb-2 d-block"
              >
                Certification ou Qualifications
              </label>
              <input
                type="text"
                id="certifications"
                placeholder="Entrez vos certifications et qualifications"
                className="form-control rounded-lg shadow-none"
                style={{ padding: "15px 20px" }}
                value={informationsUser.certifications}
                onChange={handleInputChange}
              />
            </div>
            */}
          </div>

          <div>
            <button
              className="button-p-purple border-r-40 p-20-30"
              onClick={handleNext}
            >
              Prochaine étape
            </button>
            <div className="w-100 ">
              <div
                className="progress"
                style={{ height: "15px", marginTop: "30px" }}
              >
                <div
                  className="progress-bar bg-purple"
                  role="progressbar"
                  style={{ width: `50%`, height: "15px" }}
                  aria-valuenow={40}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
