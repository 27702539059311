import { FetchUserAttributesOutput } from "aws-amplify/auth";
import { User } from "../../types/user";
import { Web3AuthNoModal } from "@web3auth/no-modal";

export function updateUserWithWeb3AndCognito(
  user: User | null,
  web3Auth: Web3AuthNoModal,
  cognitoUser: FetchUserAttributesOutput
): User {
  return {
    ...user,
    web3auth: web3Auth,
    cognitoUser: cognitoUser,
  };
}
