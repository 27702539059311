import { AuthSession, fetchAuthSession } from "aws-amplify/auth";
import { User } from "../../types/user";

export const getJwt = async () => {
  const auth = await fetchAuthSession();
  console.log("auth", auth.tokens?.accessToken.toString());
  return "Bearer " + auth.tokens?.accessToken.toString();
};

export const getUser = async (): Promise<User | null> => {
  try {
    const auth: AuthSession = await fetchAuthSession();
    

    if (!auth.tokens || !auth.tokens.accessToken) {
      throw new Error("No access token available.");
    }
    console.log(auth.tokens.accessToken.toString());
    const response = await fetch(
      "https://api.ouibild.io/api/users/me",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.tokens.accessToken.toString()}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
    const data: User = await response.json();
    return data; // This will be a User object or you need to map the data to the User type
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
    return null; // Return null in case of any error
  }
};
