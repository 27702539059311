import { User } from "../../types/user";
import { ApiService } from "../ApiService";

const userService = new ApiService();

export const getUser = async (): Promise<User | null> => {
  try {
    const user = await userService.get<User>("/users/me");
    if (user) {
      return user as User;
    } else {
      console.error("User not found or response is invalid");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    return null;
  }
};

export const postUser = async (
  userData: Partial<User>
): Promise<User | null> => {
  return await userService.post<User, Partial<User>>("/users/me", userData);
};

export const updateUser = async (
  userData: Partial<User>
): Promise<User | null> => {
  return await userService.put<User, Partial<User>>("/users/me", userData);
};

export const mintPass = async () => {
  return await userService.get<User>("/metadata/pass/mint");
};

export const getPassCard = async (userId: number): Promise<string | null> => {
  const res = await userService.get<string>(`/metadata/pass/${userId}/card`);
  console.log("res", res);
  return res;
};
