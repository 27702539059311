import React, { useState, useEffect } from "react";
import { SignUpFormStep1Props } from "../../interface/SignUpFormStepPros";
import { FormUser } from "../../types/user"; // Adjust the import based on where the interface is defined
import { updateForm } from "../../api/services/form";
import BgPurple from "../../assets/background/backgroundPurpleGradient.png";
import LogoOuibild from "../../assets/logo/logoFullPurple.svg";
import validationIcon from "../../assets/logo/validationLogo.png";
import CustomModal from "../modal/CustomModal";
import { validateStep } from "./validateStep";

export const SignUpFormStep6: React.FC<SignUpFormStep1Props> = ({
  setStep,
  setInformationsUser,
  informationsUser,
  handleNextStep,
  handlePrevStep,
}) => {
  const [investedInTokens, setInvestedInTokens] = useState<boolean | null>(
    null
  );
  const [wantsGuide, setWantsGuide] = useState<boolean | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSelectInvestedInTokens = (value: boolean) => {
    setInvestedInTokens(value);
    setInformationsUser((prevInfo: FormUser) => ({
      ...prevInfo,
      investedInTokens: value,
    }));
  };

  const handleSelectGuide = (value: boolean) => {
    setWantsGuide(value);
    setInformationsUser((prevInfo: FormUser) => ({
      ...prevInfo,
      wantsGuide: value,
    }));
  };

  const confirmStep = async () => {
    console.log("informationsUser", informationsUser);
    if (validateStep(6, informationsUser)) {
      await updateForm(informationsUser);
      setIsModalVisible(true);
    }
  };

  // const handleStepMint = () => {
  //   handleNextStep();
  // };

  return (
    <>
      <CustomModal show={isModalVisible}>
        <div className="d-flex justify-content-around align-items-center flex-column gap-30">
          <img
            src={validationIcon}
            alt="Validation Icon"
            className="loading-icon"
          />
          <h2 className="m-1 mt-4 title-35 bold">
            <span className="purple">Inscription</span> réussie !
          </h2>
          <p className="title-16 bold-400">
            Vous avez terminé votre inscription sur Ouibild. Il ne vous reste
            plus qu'une étape pour devenir partenaire privilégié : obtenez dès
            maintenant votre OuiPass et accédez à des avantages exclusifs.
          </p>
          <a href="/mint" className="button-p-purple p-20-30">
            Obtenir votre OuiPass
          </a>
        </div>
      </CustomModal>
      <div
        className="container d-flex align-items-center justify-content-center min-vh-100"
        data-aos="fade-up"
        data-aos-duration={700}
        data-aos-delay={300}
      >
        <div className="row justify-content-center w-100">
          <div
            className="col-12 col-md-10 col-lg-6"
            style={{
              transform: window.innerWidth >= 992 ? "scale(0.9)" : "scale(1)",
            }}
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={400}
          >
            <div
              className="d-flex gap-40 mb-4"
              data-aos-duration={700}
              data-aos-delay={300}
            >
              <a href="/">
                <img src={LogoOuibild} alt="logo" />
              </a>
            </div>
            <div
              className="row text-start mb-2"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={500}
            >
              <button
                className="button-transparent d-flex align-items-center"
                onClick={handlePrevStep}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <g>
                    <path
                      d="M6.52385 9.66658H16.6668V11.3332H6.52385L10.9938 15.8032L9.81533 16.9817L3.3335 10.4999L9.81533 4.01807L10.9938 5.19657L6.52385 9.66658Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>{" "}
                Retour
              </button>
            </div>
            <h2
              className="title-40 bold mb-20"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={600}
            >
              Avez-vous déjà investi dans des produits tokenisés ?
            </h2>
            <div className="d-flex flex-column gap-10 w-100 mx-40">
              <div
                className={`objectif-box d-flex justify-content-between align-items-center ${
                  investedInTokens === true ? "selected" : ""
                }`}
                onClick={() => handleSelectInvestedInTokens(true)}
              >
                <span className="text-start">Oui</span>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="investedInTokens"
                    value="true"
                    checked={investedInTokens === true}
                    onChange={() => handleSelectInvestedInTokens(true)}
                    className="form-check-input"
                  />
                  <span className="custom-radio"></span>
                </div>
              </div>
              <div
                className={`objectif-box d-flex justify-content-between align-items-center ${
                  investedInTokens === false ? "selected" : ""
                }`}
                onClick={() => handleSelectInvestedInTokens(false)}
              >
                <span className="text-start">Non</span>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="investedInTokens"
                    value="false"
                    checked={investedInTokens === false}
                    onChange={() => handleSelectInvestedInTokens(false)}
                    className="form-check-input"
                  />
                  <span className="custom-radio"></span>
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${BgPurple})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                  minHeight: "100vh !important",
                  paddingLeft: "20px",
                }}
                className="border rounded py-2"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column gap-20">
                    <h3 className="title-20 bold white">
                      Souhaitez-vous recevoir le guide de la tokenisation
                      gratuitement ?
                    </h3>
                    <p className="title-14 bold-400 white opacity-08">
                      Rédigez pour la compréhension de tous, par nos experts
                      Ouibild
                    </p>
                    <div className="d-flex">
                      <div
                        className={`guide-box d-flex justify-content-between align-items-center me-3 ${
                          wantsGuide === true ? "selected" : ""
                        }`}
                        onClick={() => handleSelectGuide(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          className="me-2"
                        >
                          <path
                            d="M6.66647 10.6138L12.7947 4.4856L13.7375 5.4284L6.66647 12.4994L2.42383 8.25685L3.36664 7.31405L6.66647 10.6138Z"
                            fill="currentColor"
                          />
                        </svg>
                        <span className="text-start">Oui</span>
                      </div>
                      <div
                        className={`guide-box d-flex justify-content-between align-items-center ${
                          wantsGuide === false ? "selected" : ""
                        }`}
                        onClick={() => handleSelectGuide(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          className="me-2"
                        >
                          <path
                            d="M6.66647 10.6138L12.7947 4.4856L13.7375 5.4284L6.66647 12.4994L2.42383 8.25685L3.36664 7.31405L6.66647 10.6138Z"
                            fill="currentColor"
                          />
                        </svg>
                        <span className="text-start">Non</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="button-p-purple mt-1 border-r-40 p-20-30"
              onClick={confirmStep}
            >
              Finaliser mon inscription
            </button>
            <div className="w-100 ">
              <div
                className="progress"
                style={{ height: "15px", marginTop: "30px" }}
              >
                <div
                  className="progress-bar bg-purple"
                  role="progressbar"
                  style={{ width: `100%`, height: "15px" }}
                  aria-valuenow={100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
