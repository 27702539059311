import React, { useState } from "react";
import { SignUpFormStep1Props } from "../../interface/SignUpFormStepPros";
import LogoOuibild from "../../assets/logo/logoFullPurple.svg";

import { FormUser } from "../../types/user";
import { toast } from "react-toastify";

export const FIND_INNOVATIVE_INVESTMENTS = "FIND_INNOVATIVE_INVESTMENTS";
export const MANAGE_PORTFOLIOS_EFFICIENTLY = "MANAGE_PORTFOLIOS_EFFICIENTLY";
export const TRACK_PERFORMANCE_REAL_TIME = "TRACK_PERFORMANCE_REAL_TIME";
export const OTHER = "OTHER";

export const SignUpFormStep5: React.FC<SignUpFormStep1Props> = ({
  setStep,
  setInformationsUser,
  informationsUser,
  handleNextStep,
  handlePrevStep,
}) => {

  const [otherInput, setOtherInput] = useState(
    informationsUser.otherChallenge || ""
  );

  const experienceOptions = [
    "Trouver des produits d'investissement innovants",
    "Gérer les portefeuilles de manière efficace",
    "Suivre les performances en temps réel",
    "Autres (veuillez préciser)",
  ];

  const challengeMapping: { [key: string]: string } = {
    "Trouver des produits d'investissement innovants":
      FIND_INNOVATIVE_INVESTMENTS,
    "Gérer les portefeuilles de manière efficace":
      MANAGE_PORTFOLIOS_EFFICIENTLY,
    "Suivre les performances en temps réel": TRACK_PERFORMANCE_REAL_TIME,
    "Autres (veuillez préciser)": OTHER,
  };

  // Handle selecting/deselecting challenges
  const handleSelect = (experience: string) => {

    const mappedValue = challengeMapping[experience];

    setInformationsUser((prevInfo: FormUser) => {
      const isSelected = prevInfo.challenges.includes(mappedValue);

      if (isSelected) {
        // If selected, remove it from the array
        return {
          ...prevInfo,
          challenges: prevInfo.challenges.filter((item) => item !== mappedValue),
          otherChallenge:
            experience === "Autres (veuillez préciser)" ? "" : prevInfo.otherChallenge,
        };
      } else {
        // If not selected, add it to the array
        return {
          ...prevInfo,
          challenges: [...prevInfo.challenges, mappedValue],
          otherChallenge:
            experience === "Autres (veuillez préciser)" ? otherInput : prevInfo.otherChallenge,
        };
      }
    });
  };

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOtherInput(value);
    setInformationsUser((prevInfo: FormUser) => ({
      ...prevInfo,
      otherChallenge: value,
    }));
  };

  const handleNext = () => {

    if (informationsUser.challenges.length === 0) {
      toast.error("Veuillez sélectionner au moins un défi.");
      return;
    }
    handleNextStep();
  };

  const handlePrev = () => {
    handlePrevStep();
  };

  return (
    <div
      className="container d-flex align-items-center justify-content-center min-vh-100"
      data-aos="fade-up"
      data-aos-duration={700}
      data-aos-delay={300}
    >
      <div className="row justify-content-center w-100">
        <div
          className="col-12 col-md-10 col-lg-6"
          style={{
            transform: window.innerWidth >= 992 ? "scale(0.9)" : "scale(1)",
          }}
          data-aos="fade-up"
          data-aos-duration={700}
          data-aos-delay={400}
        >
          <div
            className="d-flex gap-40 mb-4"
            data-aos-duration={700}
            data-aos-delay={300}
          >
            <a href="/">
              <img src={LogoOuibild} alt="logo" />
            </a>
          </div>
          <div className="row text-start mb-2">
            <button
              className="button-transparent d-flex align-items-center"
              onClick={handlePrev}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <g>
                  <path
                    d="M6.52385 9.66658H16.6668V11.3332H6.52385L10.9938 15.8032L9.81533 16.9817L3.3335 10.4999L9.81533 4.01807L10.9938 5.19657L6.52385 9.66658Z"
                    fill="currentColor"
                  />
                </g>
              </svg>{" "}
              Retour
            </button>
          </div>
          <h2 className="title-40 bold mb-20">
            Quels défis rencontrez-vous actuellement dans votre travail?
          </h2>
          <div className="d-flex flex-column gap-10 w-100 mx-40">
            {experienceOptions.map((experience, index) => (
              <div
                key={experience}
                className={`objectif-box d-flex justify-content-between align-items-center ${

                  informationsUser.challenges.includes(challengeMapping[experience])
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleSelect(experience)}
              >
                <span className="text-start">{experience}</span>
                <div className="d-flex align-items-center">
                  <input

                    type="checkbox" // Changed to checkbox for multiple selection
                    name="experience"
                    value={experience}
                    checked={informationsUser.challenges.includes(
                      challengeMapping[experience]
                    )}
                    onChange={() => handleSelect(experience)}
                    className="form-check-input"
                  />
                  <span className="custom-checkbox"></span>
                </div>
              </div>
            ))}

            {informationsUser.challenges.includes(OTHER) && (
              <div className="mt-3">
                <label
                  htmlFor="otherInput"
                  className="title-14 bold mb-2 d-block"
                >
                  Veuillez préciser
                </label>
                <input
                  type="text"
                  id="otherInput"
                  placeholder="Précisez votre réponse"
                  className="form-control rounded-lg shadow-none input-transparent"
                  style={{ padding: "15px 20px" }}
                  value={otherInput}
                  onChange={handleOtherInputChange}
                />
              </div>
            )}
          </div>

          <button className="button-p-purple  p-20-30" onClick={handleNext}>
            Prochaine étape
          </button>
          <div className="w-100 ">
            <div
              className="progress"
              style={{ height: "15px", marginTop: "30px" }}
            >
              <div
                className="progress-bar bg-purple"
                role="progressbar"
                style={{ width: `75%`, height: "15px" }}

                aria-valuenow={80}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
