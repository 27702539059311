import { useState, useCallback } from "react"
import RPC from "../evm.ethers" // Assuming RPC is correctly imported
import { useWeb3Auth } from "../contexts/Web3AuthContext"
import {
    FetchUserAttributesOutput,
    confirmSignIn,
    fetchAuthSession,
    fetchUserAttributes,
    signOut,
} from "aws-amplify/auth"
import { IProvider, WALLET_ADAPTERS } from "@web3auth/base"

interface useWeb3ActionsReturn {
    authenticateUser: () => Promise<FetchUserAttributesOutput | null | undefined>
    getUserInfo: () => Promise<void>
    logout: () => Promise<void>
    getAccounts: () => Promise<string | undefined>
    getBalance: () => Promise<void>
    signMessage: () => Promise<void>
    sendTransaction: () => Promise<void>
    connect: () => Promise<IProvider | null>
    verifyOtp: (otp: string) => Promise<void>
    permit: () => Promise<void>
    output: string
}

const useWeb3Actions = (): useWeb3ActionsReturn => {
    const { web3auth, setLoggedIn } = useWeb3Auth()
    const [output, setOutput] = useState("")
    const { setAuthSession } = useWeb3Auth()

    const uiConsole = useCallback((...args: any[]) => {
        setOutput(JSON.stringify(args || {}, null, 2))
    }, [])
    async function handleSignOut() {
        await web3auth!.logout({ cleanup: true })
    }
    const verifyOtp = async (otp: string) => {
        try {
            const user = await confirmSignIn({ challengeResponse: otp })
            console.log("User confirmed sign in", user)
            if (user.isSignedIn) {
                console.log("User is signed in (condition)")
                const auth = await fetchAuthSession()
                setAuthSession(auth)
            }
        } catch (err: any) {
            console.error("Error verifying OTP:", err)
        }
    }

    const connect = async () => {
        const auth = await fetchAuthSession()

        const web3authProvider = await web3auth!.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "jwt",
            web3AuthNetwork: "sapphire_devnet",
            extraLoginOptions: {
                id_token: auth.tokens?.idToken?.toString(), // in JWT Format
                domain: "ouibuild.auth.eu-central-1.amazoncognito.com",
                verifierIdField: "sub", // same as your JWT Verifier ID
            },
        })
        return web3authProvider
    }

    const authenticateUser = async () => {
        if (!web3auth) {
            uiConsole("web3auth not initialized yet")
            return
        }
        try {
            const res = await fetchUserAttributes()
            console.log(res)
            return res
        } catch (err) {
            console.log(err)
        }
    }

    const getUserInfo = async () => {
        if (!web3auth) {
            uiConsole("web3auth not initialized yet")
            return
        }
    }

    const logout = async () => {
        if (!web3auth) {
            uiConsole("web3auth not initialized yet")
            return
        }
        try {
            await handleSignOut()
        } catch (err) {
            console.error("onSignOut error: ", err)
        }
        try {
            await signOut()
        } catch (err) {
            console.error("onSignOut error: ", err)
        }
        setLoggedIn(false)
    }
    // const getCurrentUser = async (): Promise<User | null> => {
    //   const user = await fetch("https://api-metalskin.xyz/api/users/me")
    //   const res = await user.json()
    //   console.log("Get user", res)
    //   return res.data as User
    // }

    const getAccounts = async () => {
        if (!web3auth?.provider) {
            uiConsole("provider not initialized yet")
            return
        }
        const rpc = new RPC(web3auth.provider)
        const userAccount = await rpc.getAccounts()
        console.log(userAccount)
        return userAccount
    }

    const getBalance = async () => {
        if (!web3auth?.provider) {
            uiConsole("provider not initialized yet")
            return
        }
        const rpc = new RPC(web3auth.provider)
        const balance = await rpc.getBalance()
        uiConsole(balance)
    }

    const signMessage = async () => {
        if (!web3auth?.provider) {
            uiConsole("provider not initialized yet")
            return
        }
        const rpc = new RPC(web3auth.provider)
        const result = await rpc.signMessage()
        uiConsole(result)
    }

    const sendTransaction = async () => {
        if (!web3auth?.provider) {
            uiConsole("provider not initialized yet")
            return
        }
        const rpc = new RPC(web3auth.provider)
        const result = await rpc.signAndSendTransaction()
        uiConsole(result)
    }

    const permit = async () => {
        // const user = await getAccounts()
        // const tokenAddress = ""
        // const provider = web3auth?.provider
        // const allowanceProvider = new AllowanceProvider(provider, PERMIT2_ADDRESS)
        // const {
        //   amount: permitAmount,
        //   expiration,
        //   nonce,
        // } = allowanceProvider.getAllowanceData(user[0], tokenAddress, ROUTER_ADDRESS)
    }

    return {
        authenticateUser,
        getUserInfo,
        logout,
        getAccounts,
        getBalance,
        signMessage,
        sendTransaction,
        connect,
        verifyOtp,
        output,
        permit,
    }
}

export default useWeb3Actions
